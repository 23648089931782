import React from 'react'

import { ClientOnlyModal } from 'bl-common/src/units/ClientOnlyModal'

import { FlowComponent, FlowModalField } from '../../types'
import { getFlowValue } from '../../utils'

type ModalFieldProps = FlowComponent &
  FlowModalField['props'] & { children: React.ReactNode }

export const ModalField = (props: ModalFieldProps) => {
  return (
    <ClientOnlyModal
      show={getFlowValue(props.isOpen, props.control)}
      onHide={() => props?.onHide('', props.control)}
    >
      {props.children}
    </ClientOnlyModal>
  )
}
