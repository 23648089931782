import React from 'react'
import styled, { css, useTheme } from 'styled-components'

import { theme } from 'bl-common/src/styles/theme'
import { media, mediaObj } from 'bl-common/src/utils/media'
import {
  buildBreadcrumbField,
  buildText,
  FieldRenderer,
  FlowComponent,
} from 'bl-flows-core'

import { globalBookingMessages } from '../../messages/global'
import { Direction } from '../../types/Transportation'
import LocationSearch from './LocationSearch'
import { MapContainer } from './MapContainer'

const Wrapper = styled('div')<{ showMap: boolean }>(({ showMap }) => ({
  display: showMap ? 'none' : 'block',
  padding: `${theme.spacing[1]} ${theme.spacing[2]}  ${theme.spacing[3]}`,

  [mediaObj.mlg]: {
    padding: `${theme.spacing[3]} ${theme.spacing[5]} ${theme.spacing[3]}`,
  },

  [mediaObj.md]: {
    display: 'block',
  },
}))

// Can't use object syntax because we want to have a fallback if the browser does not support dvh
const MapWrapper = styled('div')<{ showMap: boolean }>`
  height: 100vh;
  height: 100dvh;
  display: none;

  ${({ showMap }) =>
    showMap &&
    css`
      display: block;
    `}

  ${media.md(css`
    display: none;
  `)}
`

export const LocationPicker: React.FC<FlowComponent> = ({
  control,
  screenTheme,
}) => {
  const direction = control.screen.state.direction
  const isEditing = control.flow.setupHook?.isEditing

  const theme = useTheme()
  const themeStyle =
    theme.bookingEngine?.[screenTheme]?.transportationFlow?.locationPicker

  const initialLocationStateName =
    direction === 'pickup'
      ? 'initialPickupLocationName'
      : 'initialDropoffLocationName'

  const onSelectLocation = location => {
    control.screen.setState({
      location,
    })
    // Add initialLocation to state to render as previously selected in Location step and Overview step when editing
    if (
      isEditing &&
      !control.flow?.stateRef?.current?.[initialLocationStateName]
    ) {
      control.flow.setState({
        [initialLocationStateName]: location.busStop
          ? location.busStop.name
          : location.name,
      })
    }
    control.screen.setUiState({ showMap: true })
  }

  return (
    <>
      <Wrapper showMap={control?.screen?.uiStateRef?.current?.showMap}>
        <FieldRenderer
          control={control}
          screenTheme={screenTheme}
          item={buildBreadcrumbField({
            props: {},
            layout: {
              spacing: {
                pb: { xs: 2.5 },
              },
            },
          })}
        />
        <FieldRenderer
          control={control}
          item={buildText({
            props: {
              type: 'heading',
              value:
                direction === Direction.pickup
                  ? control.context.t(
                      globalBookingMessages.text.pickupLocationTitle
                    )
                  : control.context.t(
                      globalBookingMessages.text.dropoffLocationTitle
                    ),
            },
            layout: {
              spacing: {
                pb: { xs: 0.5, md: 1 },
              },
            },
          })}
        />
        <FieldRenderer
          control={control}
          item={buildText({
            props: {
              type: 'subheading',
              value: isEditing
                ? control.context.t(
                    globalBookingMessages.text.pickupDropoffEditSubtitle,
                    {
                      direction,
                      location:
                        direction === 'pickup'
                          ? control.flow.stateRef?.current
                              ?.initialPickupLocationName
                          : control.flow.stateRef?.current
                              ?.initialDropoffLocationName,
                    }
                  )
                : control.context.t(
                    globalBookingMessages.text.pickupDropoffSubtitle
                  ),
            },
            layout: {
              spacing: {
                pb: { xs: 1, md: 2 },
              },
            },
          })}
        />
        <LocationSearch
          direction={direction}
          onSelectLocation={onSelectLocation}
          selectedLocation={control.screen.stateRef.current.location}
          placeholder={control.context.t(
            globalBookingMessages.text.pickupDropoffInputPlaceholder
          )}
          isEditing={isEditing}
          listTitle={
            direction === Direction.pickup
              ? control.context.t(globalBookingMessages.text.pickupListTitle)
              : control.context.t(globalBookingMessages.text.dropoffListTitle)
          }
          themeStyle={themeStyle?.locationSearch}
        />
      </Wrapper>
      <MapWrapper showMap={control?.screen?.uiStateRef?.current?.showMap}>
        <MapContainer
          control={control}
          onSelectLocation={onSelectLocation}
          isPickup={direction === Direction.pickup}
          confirmLabel={
            direction === Direction.pickup
              ? control.context.t(globalBookingMessages.text.pickupConfirm)
              : control.context.t(globalBookingMessages.text.dropoffConfirm)
          }
          disclaimer={control.context.t(
            globalBookingMessages.text.pickupDisclaimer
          )}
        />
      </MapWrapper>
    </>
  )
}
