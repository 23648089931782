import React from 'react'
import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { TransportationLocation } from 'bl-graphql/src/generated/hooks-with-types'

import ListItem from './ListItem'

type LocationListProps = {
  title: string
  locations: {
    id: string
    number?: string
    bus?: string
    type?: string
    name: string
    address: string
    latitude: number
    longitude: number
  }[]
  selectedLocation: TransportationLocation | null
  onItemSelect: (location) => void
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']['locationList']
}

const List = styled('div')({
  marginTop: theme.spacing[4],
})

const Item = styled('li')({
  '& + &': {
    marginTop: 5,
  },
})

export const LocationList = ({
  title,
  locations = [],
  selectedLocation,
  onItemSelect,
  themeStyle,
}: LocationListProps) => {
  return (
    <List>
      <Type preset="subtitle" weight="bold" bottom={{ xs: 1, md: 1 }}>
        {title}
      </Type>
      <ul>
        {locations.map(location => (
          <Item key={location.id}>
            <ListItem
              locationName={location.name}
              locationAddress={location.address}
              selected={selectedLocation && location.id === selectedLocation.id}
              onClick={() => onItemSelect(location)}
              themeStyle={themeStyle?.listItem}
            />
          </Item>
        ))}
      </ul>
    </List>
  )
}

export default LocationList
