import React, { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Disclaimer } from 'bl-common/src/booking/Disclaimer/Disclaimer'

import { FlowComponent, FlowDisclaimerField } from '../../types'
import { getFlowValue } from '../../utils'
import { DisclaimerTextField } from '../TextField/DisclaimerField'

type DisclaimerFieldProps = FlowComponent & FlowDisclaimerField['props']

export const DisclaimerField = ({
  showDisclaimer = true,
  ...props
}: DisclaimerFieldProps) => {
  /*
  Note: If you use the condition on the field to control visibility of the disclaimer, 
  it will animate in but not out, if you want it to animate both in and out 
  you have to use showDisclaimer instead
  */
  const value = getFlowValue(props?.value, props?.control)
  const showDisclaimerValue = getFlowValue(showDisclaimer, props?.control)
  const scrollIntoViewValue = getFlowValue(
    props?.scrollIntoView,
    props?.control
  )
  const weight = props.weight !== 'bold' ? 'normal' : 'bold'
  const textColor = props.textColor
  const elementRef = useRef<HTMLDivElement>()

  useEffect(() => {
    if (showDisclaimerValue && scrollIntoViewValue) {
      elementRef?.current?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [showDisclaimerValue])

  return (
    <AnimatePresence>
      {showDisclaimerValue && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ ease: 'linear', duration: 0.2 }}
        >
          <div ref={elementRef} style={{ scrollMarginTop: 50 }}>
            {props?.type === 'circle' ? (
              <DisclaimerTextField>{value}</DisclaimerTextField>
            ) : (
              <Disclaimer
                weight={weight}
                color={props.color}
                textColor={textColor}
                preset={props.preset}
              >
                {value}
              </Disclaimer>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
