import React from 'react'
import styled from 'styled-components'

import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { buildCardButtonField, FieldRenderer, FlowControl } from 'bl-flows-core'
import { ActivityProductAvailabilityQuery, Property } from 'bl-graphql'
import { PRODUCT_IDS, ProductId } from 'bl-utils/src/ProductIds'

import { globalBookingMessages } from '../messages'
import { privateTransferMessages } from './../subflows/privateTransfer/messages/privateTransferMessages'

interface PrivateTransferCardsProps {
  control: FlowControl
  transferAvailability: ActivityProductAvailabilityQuery['activityProductAvailability']
}

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[1.5],
})

const Arrival = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[0.5],
})

const Departure = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[0.5],
})

const Cards = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[1],
})

export const PrivateTransferCards: React.FC<PrivateTransferCardsProps> = ({
  control,
  transferAvailability,
}: PrivateTransferCardsProps) => {
  const property = control.flow.setupHook?.bookingInfo?.entryDate
    ? control.context.t(globalBookingMessages.labels.BlueLagoon)
    : control.flow.setupHook?.bookingInfo?.property.toUpperCase() ===
      Property.Silica
    ? control.context.t(globalBookingMessages.labels.SILICA)
    : control.context.t(globalBookingMessages.labels.RETREAT)

  const onArrivalClick = (id: ProductId) => {
    if (control.screen.state?.selectedArrival === id) {
      control.screen.setState({
        selectedArrival: null,
      })
    } else {
      control.screen.setState({
        selectedArrival: id,
      })
    }
  }

  const onDepartureClick = (id: ProductId) => {
    if (control.screen.state?.selectedDeparture === id) {
      control.screen.setState({
        selectedDeparture: null,
      })
    } else {
      control.screen.setState({
        selectedDeparture: id,
      })
    }
  }

  const getLowestPrice = (productId: ProductId) => {
    return transferAvailability
      ?.filter(item => item.productId === productId)
      ?.reduce((lowest, curr) => {
        if (lowest) {
          return lowest < curr.price ? lowest : curr.price
        }
        return curr.price
      }, 0)
  }

  return (
    <Container>
      <Arrival>
        <Type preset="textLarge" weight="bold">
          {control.context.t(privateTransferMessages.info.arrivalLabel)}
        </Type>
        <Cards>
          <FieldRenderer
            control={control}
            item={buildCardButtonField({
              props: {
                title: control =>
                  control.context.t(
                    privateTransferMessages.info.arrivalSmallheading
                  ),
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(
                    privateTransferMessages.info.arrivalDescription,
                    {
                      guests: 3,
                      property,
                    }
                  ),
                onClick: () => {
                  onArrivalClick(PRODUCT_IDS.PrivateTransferArrivalSmall)
                },
                priceFormat: control.context.t(
                  privateTransferMessages.info.priceFromFormat
                ),
                price: getLowestPrice(
                  PRODUCT_IDS.PrivateTransferDepartureSmall
                ),
                isClosed: false,
                inCart:
                  control.screen.state?.selectedArrival ===
                  PRODUCT_IDS.PrivateTransferArrivalSmall,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
              },
            })}
          />
          <FieldRenderer
            control={control}
            item={buildCardButtonField({
              props: {
                title: control =>
                  control.context.t(
                    privateTransferMessages.info.arrivalLargeHeading
                  ),
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(
                    privateTransferMessages.info.arrivalDescription,
                    {
                      guests: 8,
                      property,
                    }
                  ),
                onClick: () => {
                  onArrivalClick(PRODUCT_IDS.PrivateTransferArrivalLarge)
                },
                priceFormat: control.context.t(
                  privateTransferMessages.info.priceFromFormat
                ),
                price: getLowestPrice(PRODUCT_IDS.PrivateTransferArrivalLarge),
                isClosed: false,
                inCart:
                  control.screen.state?.selectedArrival ===
                  PRODUCT_IDS.PrivateTransferArrivalLarge,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
              },
            })}
          />
        </Cards>
      </Arrival>

      <Departure>
        <Type preset="textLarge" weight="bold">
          {control.context.t(privateTransferMessages.info.departureLabel)}
        </Type>
        <Cards>
          <FieldRenderer
            control={control}
            item={buildCardButtonField({
              props: {
                title: control =>
                  control.context.t(
                    privateTransferMessages.info.departureSmallHeading
                  ),
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(
                    privateTransferMessages.info.departureDescription,
                    {
                      guests: 3,
                      property,
                    }
                  ),
                onClick: () => {
                  onDepartureClick(PRODUCT_IDS.PrivateTransferDepartureSmall)
                },
                priceFormat: control.context.t(
                  privateTransferMessages.info.priceFromFormat
                ),
                price: getLowestPrice(
                  PRODUCT_IDS.PrivateTransferDepartureSmall
                ),
                isClosed: false,
                inCart:
                  control.screen.state?.selectedDeparture ===
                  PRODUCT_IDS.PrivateTransferDepartureSmall,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
              },
            })}
          />
          <FieldRenderer
            control={control}
            item={buildCardButtonField({
              props: {
                title: control =>
                  control.context.t(
                    privateTransferMessages.info.departureLargeHeading
                  ),
                ctaLabel: control =>
                  control.context.t(globalBookingMessages.buttons.select),
                description: control =>
                  control.context.t(
                    privateTransferMessages.info.departureDescription,
                    {
                      guests: 8,
                      property,
                    }
                  ),
                onClick: () => {
                  onDepartureClick(PRODUCT_IDS.PrivateTransferDepartureLarge)
                },
                priceFormat: control.context.t(
                  privateTransferMessages.info.priceFromFormat
                ),
                price: getLowestPrice(
                  PRODUCT_IDS.PrivateTransferDepartureLarge
                ),
                isClosed: false,
                inCart:
                  control.screen.state?.selectedDeparture ===
                  PRODUCT_IDS.PrivateTransferDepartureLarge,
                inCartLabel: control =>
                  control.context.t(globalBookingMessages.labels.selected),
              },
            })}
          />
        </Cards>
      </Departure>
    </Container>
  )
}
