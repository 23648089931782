import React from 'react'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  color?: string
}

export const CheckmarkIcon: React.FC<IconProps> = ({
  color = 'black',
  ...rest
}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.99 26.99" {...rest}>
      <path
        fill={color}
        d="M8.26,10.87a.5.5,0,1,0-.77.63l5.82,7.06,10-12.95A.5.5,0,0,0,22.47,5L13.29,17Z"
      />
      <path
        fill={color}
        d="M26.16,8.87l-1.52,1.84A11.72,11.72,0,0,1,25,13.5,11.5,11.5,0,1,1,13.5,2a11.33,11.33,0,0,1,5.09,1.21L19.9,1.62a13.57,13.57,0,1,0,6.26,7.25Z"
      />
    </svg>
  )
}
