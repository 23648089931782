import React from 'react'
import { useTheme } from 'styled-components'

import { Picker } from 'bl-common/src/booking/Picker/Picker'

import { FlowComponent, FlowPickerField, FlowValue } from '../../types'
import { getFlowValue } from '../../utils'

type PickerFieldProps = FlowComponent &
  FlowPickerField['props'] & {
    id: string
    error?: FlowValue
  }

export const PickerField = ({ screenTheme, ...props }: PickerFieldProps) => {
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.pickerField

  return (
    <Picker
      value={props.control.screen.state[props.id]}
      onChange={newValue => {
        props.control.screen.setState({
          [props.id]: newValue,
        })

        props.onChange?.(newValue, props.control)
      }}
      max={getFlowValue(props.max, props.control) ?? undefined}
      min={getFlowValue(props.min, props.control) ?? 0}
      text={getFlowValue(props.label, props.control)}
      onDisabledIncrementClick={value =>
        props.onDisabledIncrementClick?.(value, props.control)
      }
      themeStyle={themeStyle?.picker}
    />
  )
}
