import React from 'react'
import FocusLock from 'react-focus-lock'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Button } from 'bl-common/src/elements/Button/Button'
import { CloseIcon } from 'bl-common/src/elements/Icons/CloseIcon'
import { InfoIcon } from 'bl-common/src/elements/Icons/InfoIcon'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'

type InfoBosProps = {
  locationName: string
  locationAddress: string
  disclaimer: string
  confirmLabel: string
  onClose: () => void
  onConfirm: () => void
  themeStyle?: PartialBookingEngine['transportationFlow']['map']['infoBox']
}

const PopUp = styled(motion.div)<{
  $themeStyle?: PartialBookingEngine['transportationFlow']['map']['infoBox']
}>(({ $themeStyle }) => ({
  background: $themeStyle?.background ?? colors.white,
  bottom: theme.spacing[4],
  padding: theme.spacing[2],
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%)',
  width: '80%',

  [mediaObj.md]: {
    bottom: theme.spacing[2],
    width: '60%',
  },
}))

const Disclaimer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.spacing[1],

  [mediaObj.md]: {
    paddingBottom: theme.spacing[2],
  },
})

const Icon = styled(InfoIcon)({
  flex: `0 0 ${theme.spacing[1.5]}`,

  [mediaObj.md]: {
    flex: `0 0 ${theme.spacing[1]}`,
    paddingTop: `calc(${theme.spacing[1]} * .16)`,
  },
  alignSelf: 'flex-start',
})

const Close = styled('button')({
  cursor: 'pointer',
  height: 12,
  width: 12,
  position: 'absolute',
  margin: theme.spacing[1],
  right: 0,
  top: 1,
})

export const InfoBox = ({
  locationName,
  locationAddress,
  disclaimer,
  confirmLabel,
  onClose,
  onConfirm,
  themeStyle,
}: InfoBosProps) => (
  <FocusLock autoFocus={true} returnFocus={true}>
    <PopUp
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      }}
      initial={{ y: 30, x: '-50%', opacity: 0 }}
      $themeStyle={themeStyle}
    >
      <Type preset="textLarge" weight="bold" bottom={{ xs: 0.5 }}>
        {locationName}
      </Type>
      <Type preset="text" bottom={{ xs: 1, md: 1.5 }}>
        {locationAddress}
      </Type>
      {disclaimer && (
        <Disclaimer>
          <Icon
            color={themeStyle?.disclaimerIconColor ?? colors.fountainBlue}
          />
          <Type preset="text" left={{ xs: 0.5 }} weight="bold">
            {disclaimer}
          </Type>
        </Disclaimer>
      )}
      <Button
        paddingSize="small"
        onClick={onConfirm}
        style={{ width: '100%' }}
        type="button"
        preset={themeStyle?.buttonPreset}
      >
        {confirmLabel}
      </Button>
      <Close onClick={onClose} type="button">
        <CloseIcon color={themeStyle?.closeIconColor} />
      </Close>
    </PopUp>
  </FocusLock>
)

export default InfoBox
