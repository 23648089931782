import React, { ReactNode, useState } from 'react'
import { IntlFormatters, MessageDescriptor } from 'react-intl'
import { TFunction } from 'next-i18next'
import { AnimatePresence } from 'framer-motion'

import { colors } from '../../constants/colors'
import { CloseIcon } from '../../elements/Icons/CloseIcon'
import { Type } from '../../elements/Typography/Typography'
import { PartialBookingEngine } from '../../styles/types'
import * as styles from './styles'

type CommonProps = {
  items: {
    time: string
    date: string
    title: string
    subtitle: string
    faded?: boolean
    showDate?: boolean
  }[]
  isHotelBooking?: boolean
  forceExpansion?: boolean
  children?: ReactNode
  moreAriaLabel?: string
  themeStyle?: PartialBookingEngine['itineraryField']
}

type TimelinePropsWithString = CommonProps & {
  moreLabel?: string
  legacyT: TFunction
  t?: never
}

type TimelinePropsWithMessageDescriptor = CommonProps & {
  moreLabel?: MessageDescriptor
  t: IntlFormatters['formatMessage']
  legacyT?: never
}

export type TimelineProps =
  | TimelinePropsWithString
  | TimelinePropsWithMessageDescriptor

export const Timeline = ({
  items = [],
  isHotelBooking = false,
  forceExpansion = false,
  children,
  moreLabel,
  moreAriaLabel,
  themeStyle,
  t,
  legacyT,
}: TimelineProps) => {
  const [expanded, expand] = useState(forceExpansion)

  const editedItems = items.map((item, i) => {
    if (item.date !== items[i - 1]?.date) {
      item.showDate = true
    }
    return item
  })

  /*  split items three arrays
      1. first item
      2. all middle items (initially hidden)
      3. last item
  */
  const [first, hidden, last] = editedItems.reduce(
    ([first, hidden, last], current, index) => {
      if (index === 0) {
        return [[current], [], []]
      } else if (index === items.length - 1) {
        return [first, [...hidden], [current]]
      } else {
        return [first, [...hidden, current], last]
      }
    },
    [[], [], []]
  )

  const toggleOpen = () => {
    expand(!expanded)
  }

  const showLastDate = first?.[0]?.date !== last?.[0]?.date && !expanded

  const label = !moreLabel
    ? `+${hidden.length} Items`
    : typeof moreLabel === 'string'
    ? legacyT(moreLabel, { count: hidden.length })
    : t(moreLabel, { count: hidden.length })
  const ariaLabel = !moreAriaLabel
    ? `${hidden.length} more items, press to expand`
    : typeof moreAriaLabel === 'string'
    ? legacyT(moreAriaLabel, { count: hidden.length })
    : t(moreAriaLabel, { count: hidden.length })

  return (
    <styles.Timeline>
      {children}
      {first.map(item => (
        <styles.TimelineItem
          key={`${item.title}-${item.time}`}
          style={{ opacity: item?.faded ? 0.5 : 1 }}
        >
          <styles.Time hasMinWidth={isHotelBooking}>
            <Type preset="labelSmall">
              {isHotelBooking && item?.showDate && item.date + ' '}
              {item?.time}
            </Type>
          </styles.Time>
          <styles.Content themeStyle={themeStyle}>
            <Type preset="labelSmall" weight="bold" bottom={{ xs: 0.5 }}>
              {item.title}
            </Type>
            <Type preset="labelSmall" opacity={0.5}>
              {item.subtitle}
            </Type>
            {hidden.length > 0 && !forceExpansion && (
              <styles.ExtraItemsButton
                onClick={toggleOpen}
                aria-controls="hidden-items"
                aria-expanded={expanded}
                aria-label={ariaLabel}
                animate={expanded ? 'open' : 'closed'}
                variants={{
                  closed: {
                    opacity: 1,
                    height: 'auto',
                    transition: { delay: 0.6 },
                  },
                  open: {
                    opacity: 0,
                    height: 0,
                  },
                }}
                transition={{ duration: 0.2 }}
                type="button"
                $themeStyle={themeStyle}
              >
                <Type preset="labelSmall" weight="bold" top={{ xs: 1 }}>
                  {label}
                </Type>
              </styles.ExtraItemsButton>
            )}
            <AnimatePresence>
              {expanded && !forceExpansion && (
                <styles.Close
                  onClick={toggleOpen}
                  aria-label={'press to hide'}
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={{
                    open: { opacity: 1 },
                    closed: { opacity: 0 },
                  }}
                >
                  <CloseIcon
                    style={{ height: '12px', width: '12px' }}
                    color={themeStyle?.closeButtonColor ?? colors.black}
                  />
                </styles.Close>
              )}
            </AnimatePresence>
          </styles.Content>
        </styles.TimelineItem>
      ))}
      <AnimatePresence initial={false}>
        {expanded && (
          <styles.Hidden
            id="hidden-items"
            aria-hidden={!expanded}
            initial="closed"
            animate="open"
            exit="closed"
            variants={{
              open: {
                height: 'auto',
                transition: { duration: 0.5, delay: 0.3 },
              },
              closed: { height: 0 },
            }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            {hidden.map((item, index) => (
              <styles.TimelineItem
                key={`${item.title}-${index}`}
                style={{ opacity: item?.faded ? 0.5 : 1 }}
              >
                <styles.Time hasMinWidth={isHotelBooking}>
                  <Type preset="labelSmall">
                    {isHotelBooking && item?.showDate && item.date + ' '}
                    {item.time}
                  </Type>
                </styles.Time>
                <styles.Content themeStyle={themeStyle}>
                  <Type preset="labelSmall" weight="bold" bottom={{ xs: 0.5 }}>
                    {item.title}
                  </Type>
                  <Type preset="labelSmall" opacity={0.5}>
                    {item.subtitle}
                  </Type>
                </styles.Content>
              </styles.TimelineItem>
            ))}
          </styles.Hidden>
        )}
      </AnimatePresence>
      {last.map(item => (
        <styles.TimelineItem
          key={`${item.title}-${item.time}`}
          style={{ opacity: item?.faded ? 0.5 : 1 }}
        >
          <styles.Time hasMinWidth={isHotelBooking}>
            <Type preset="labelSmall">
              {isHotelBooking &&
                (item?.showDate || showLastDate) &&
                item.date + ' '}
              {item.time}
            </Type>
          </styles.Time>
          <styles.Content themeStyle={themeStyle}>
            <Type preset="labelSmall" weight="bold" bottom={{ xs: 0.5 }}>
              {item.title}
            </Type>
            <Type preset="labelSmall" opacity={0.5}>
              {item.subtitle}
            </Type>
          </styles.Content>
        </styles.TimelineItem>
      ))}
    </styles.Timeline>
  )
}
