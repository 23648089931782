import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { InfoIcon } from 'bl-common/src/elements/Icons/InfoIcon'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { media } from 'bl-common/src/utils/media'

export const Disclaimer = styled.div`
  align-items: flex-start;
  display: flex;
  order: -1;
`
export const Icon = styled(InfoIcon)`
  flex: 0 0 ${({ theme }) => theme.spacing[1.5]};
  padding-top: 2px;

  ${media.md(css`
    flex: 0 0 ${({ theme }) => theme.spacing[1]};
  `)}
`

export const DisclaimerTextField = ({ children }: { children: ReactNode }) => {
  return (
    <Disclaimer>
      <Icon color={colors.deepBlue} />
      <Type preset="text" left={{ xs: 0.5 }}>
        {children}
      </Type>
    </Disclaimer>
  )
}
