import React from 'react'
import { useTheme } from 'styled-components'

import { Timeline } from 'bl-common/src/booking/Timeline/Timeline'

import { FlowComponent, FlowItineraryField } from '../../types'
import { getFlowValue } from '../../utils'

type ItineraryFieldProps = FlowComponent & FlowItineraryField['props']

export const ItineraryField = ({
  screenTheme,
  moreLabel,
  ...props
}: ItineraryFieldProps) => {
  const items = getFlowValue(props.items, props.control)

  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.itineraryField

  return (
    <Timeline
      items={items}
      isHotelBooking={props.isHotelBooking}
      themeStyle={themeStyle}
      moreLabel={moreLabel}
      t={props.control.context.t}
    />
  )
}
