import React from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { useTheme } from 'styled-components'

import { PaginationItem } from 'bl-common/src/booking/Pagination/styles'
import { media } from 'bl-common/src/utils/media'

import { TopBar } from '../../fields/BreadcrumbField'
import { renderFields } from '../../renderers/renderUtils'
import {
  FlowFullScreenLayout,
  FlowFullScreenLayoutFields,
  FlowLayoutComponent,
} from '../../types'
import { WithScreenTheme } from '../../types/typeUtils'
import { layoutAnimation } from '../ImageLayout/styles'

export const ContentContainer = styled(motion.div)<WithScreenTheme>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;

  ${TopBar} {
    ${media.md(css`
      border-bottom: none;
      margin-bottom: 0;
    `)}
  }

  padding: ${({ theme }) =>
    `${theme.spacing[0.5]} ${theme.spacing[2]}  ${theme.spacing[3]}`};
  min-height: 100%;

  ${media.mlg(css`
    padding: ${({ theme }) =>
      `${theme.spacing[2]} ${theme.spacing[5]} ${theme.spacing[3]}`};
  `)}

  ${media.bmd(css`
    overflow-y: auto;
    height: 100%;
  `)}

  ${({ screentheme, screenthemes }) =>
    screentheme &&
    screenthemes[screentheme] &&
    css`
      background: ${screenthemes[screentheme].background || 'inherit'};
      color: ${screenthemes[screentheme].color || 'inherit'};
    `};

  ${PaginationItem} {
    width: fit-content;

    button {
      margin-right: ${({ theme }) => `${theme.spacing[4]}`};
    }
  }
`
type FullScreenLayoutProps = FlowLayoutComponent<FlowFullScreenLayoutFields> &
  FlowFullScreenLayout['props']

export const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({
  control,
  fields,
  screenTheme,
}) => {
  const theme = useTheme()
  const screenThemes = theme?.bookingEngineScreenThemes

  return (
    <React.Fragment>
      <ContentContainer
        {...layoutAnimation}
        screentheme={screenTheme}
        screenthemes={screenThemes}
      >
        {renderFields(fields.main, control, screenTheme)}
      </ContentContainer>
    </React.Fragment>
  )
}
