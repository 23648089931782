import React from 'react'

import { IMyBlueLagoonUpgradeItemFields } from 'bl-common/src/generated/contentful'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildCustomField,
  buildFlow,
  buildHeading,
  buildScreenWithImageLayout,
} from 'bl-flows-core'

import { UpgradeCard } from '../../types/UpgradeCard'
import { UpgradeCards } from './components/UpgradeCards'
import { upgradeGroupMessages } from './messages/upgradeGroupMessages'

const layoutImages: Partial<
  Record<IMyBlueLagoonUpgradeItemFields['flowType'][number], string>
> = {
  upgradeDiningReservations:
    'https://images.ctfassets.net/w65k7w0nsb8q/40mpCmKXiONhi3nR5Ikeof/e535280f87e0589f12b833198b009b04/DSC_0704.jpg?w=1600',
  upgradeInWaterTreatments:
    'https://images.ctfassets.net/w65k7w0nsb8q/1fCCvQiKS1sJRU7SnskAqe/a1dfc2877b14111f17d3f26d6c634f6d/Floating_CR-13.jpg?w=1600',
  upgradeTransfer:
    'https://images.ctfassets.net/w65k7w0nsb8q/qu5VqlhlLMNYyHnVSrVE7/68c79a8bb3095eb7a8c63604350d2785/DJI_0878.jpg?w=1600',
  upgradeToursAndActivities:
    'https://images.ctfassets.net/w65k7w0nsb8q/3c2lnuILAKp45Qx6O4inV2/dd7527e0bcf3d8bae6ab2a3d12877d77/RT_Activities_Helicopter_CRL_0723-UNL-6.jpg?w=1600',
  upgradeIndoorTreatments:
    'https://images.ctfassets.net/w65k7w0nsb8q/3OPpEcKqlv665xTBQRVO5M/cb45e913252b083884e41c6eae4d50ca/Retreat_Spa_Bench_Massage.jpg?w=1600',
  upgradeWineCellar:
    'https://images.ctfassets.net/w65k7w0nsb8q/11X9CfnCnZ91MGgS4KSqJ2/fc4edabf69eec982655a9bc077363c1e/wine-cellar.jpg?w=1600',
  upgradeHighlandBaseToursAndActivities:
    'https://images.ctfassets.net/tgn1zxvn6yc5/2XlKfVydji4C47IMFIINMU/613917bcc8dede5210afa7fd34f42c15/IMG_4069.jpg?w=1600',
  upgradeHighlandBaseDining:
    'https://images.ctfassets.net/tgn1zxvn6yc5/yJDu1iT9jaKnJxLVIniGP/301522a8b4d45205dce35fbfc33f2c71/HB_Hotel_Exterior_CR_1123-UNL-43.jpg?w=1600',
  upgradeHighlandBaseTransfer:
    'https://images.ctfassets.net/tgn1zxvn6yc5/5K2cnpC1CgTPGH0uF3xxXQ/bb29db7301e15cf674aeb5cd41b779c8/Amazing_tour-_jeep.jpg?w=1600',
}

interface UpgradeGroupFlowInitialData {
  upgradeGroupItem: UpgradeCard
  onClickCard: (card: UpgradeCard) => void
  screenTheme: ScreenTheme
}

export const buildUpgradeGroupFlow = (
  _,
  __,
  initialData?: UpgradeGroupFlowInitialData
) =>
  buildFlow({
    id: 'upgradeParentFlow',
    routerSettings: {
      updateHistory: false,
    },
    settings: {
      resetWhenOpened: true,
    },
    setupHook: () => {
      return {
        onClickCard: initialData?.onClickCard,
      }
    },
    children: [
      buildScreenWithImageLayout({
        id: 'upgrade-group',
        subType: 'form',
        layoutProps: {
          layoutId: 'upgrade-group-image-layout',
          layoutImageSrc:
            layoutImages[initialData.upgradeGroupItem.flowType] ||
            initialData.upgradeGroupItem.image.fields.file.url,
        },
        breadcrumb: false,
        theme: initialData?.screenTheme,
        fields: {
          main: [
            ...buildHeading({
              title: control => {
                const message =
                  upgradeGroupMessages.info[
                    `${initialData.upgradeGroupItem.flowType}Title`
                  ]
                return message
                  ? control.context.t(message)
                  : initialData?.upgradeGroupItem.title
              },

              subTitle: control => {
                const message =
                  upgradeGroupMessages.info[
                    `${initialData.upgradeGroupItem.flowType}Subtitle`
                  ]
                return message
                  ? control.context.t(message)
                  : initialData?.upgradeGroupItem.description
              },
              includeBreadcrumb: false,
              mtTitle: { xs: 2 },
            }),
            buildCustomField({
              defaultValue: null,
              props: {
                component: props => (
                  <UpgradeCards
                    {...props}
                    cards={initialData?.upgradeGroupItem.subUpgradeCards}
                  />
                ),
              },
            }),
          ],
        },
      }),
    ],
  })
