import React, { CSSProperties } from 'react'
import styled from 'styled-components'

import { ChevronLeft } from 'bl-common/src/elements/Icons/ChevronLeft'
import { ChevronRight } from 'bl-common/src/elements/Icons/ChevronRight'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import {
  displayDate,
  formatDateInUTC,
} from 'bl-utils/src/formatting/formatDate'

const DatePickerContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing[1],
  alignItems: 'center',
})

const ChangeDateButton = styled.button({
  cursor: 'pointer',
  padding: theme.spacing[0.5],
})

export const DatePicker = ({
  date,
  color,
  locale,
  style,
  onPrevDayClick,
  onNextDayClick,
}: {
  date: Date
  color: string
  locale: 'is' | 'en'
  style?: CSSProperties
  onPrevDayClick: () => void
  onNextDayClick: () => void
}) => {
  return (
    <DatePickerContainer style={style}>
      <ChangeDateButton
        type="button"
        onClick={() => {
          onPrevDayClick?.()
        }}
      >
        <ChevronLeft color={color} height={14} />
      </ChangeDateButton>

      <Type preset="text" weight="bold" case="uppercase">
        {formatDateInUTC(date, 'EEEE', locale)}{' '}
        <span
          style={{
            color,
          }}
        >
          {displayDate(new Date(date), { locale })}
        </span>
      </Type>

      <ChangeDateButton type="button" onClick={() => onNextDayClick?.()}>
        <ChevronRight color={color} height={14} />
      </ChangeDateButton>
    </DatePickerContainer>
  )
}
