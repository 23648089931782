import React from 'react'

import { ProgressiveImage } from 'bl-common/src/elements/ProgressiveImage'

// import { useTranslation } from 'react-i18next'
import { FlowComponent, FlowImageField } from '../../types'
import { getFlowValue } from '../../utils'

type ImageFieldProps = FlowComponent & FlowImageField['props']

export const ImageField = ({
  control,
  imageSrc,
  // alt,
  height,
}: ImageFieldProps) => {
  // TODO: describe image
  // const { t } = useTranslation('booking')

  const imageValue = getFlowValue(imageSrc, control)

  return (
    <div style={{ width: '100%', height }}>
      <ProgressiveImage image={imageValue} />
    </div>
  )
}
