import React, { useEffect, useMemo, useRef } from 'react'
import { addDays } from 'date-fns/addDays'
import { addMonths } from 'date-fns/addMonths'
import { differenceInCalendarMonths } from 'date-fns/differenceInCalendarMonths'
import { getDaysInMonth } from 'date-fns/getDaysInMonth'
import { isSameDay } from 'date-fns/isSameDay'
import { startOfMonth } from 'date-fns/startOfMonth'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { displayDate } from 'bl-utils/src/formatting/formatDate'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { FlowCalendarField, FlowComponent } from '../../types'
import { getFlowValue } from '../../utils'

type CalendarFieldProps = FlowComponent &
  FlowCalendarField['props'] & {
    id: string
  }

type SharedProps = {
  themeStyle?: PartialBookingEngine['calendarField']
}

interface DayProps {
  selected?: boolean
  themeStyle?: PartialBookingEngine['calendarField']
}

const Month = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const DayLabel = styled.div`
  width: ${100 / 7}%;
  display: inline-block;
  text-align: center;
  padding: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
`

const disabledDayCSS = css<SharedProps>`
  color: ${props => props?.themeStyle?.disabledDayColor ?? colors.dark};
  opacity: 0.4;
`

const selectedDayCSS = css<SharedProps>`
  color: ${props => props?.themeStyle?.selectedDayColor ?? colors.white};
  background-color: ${props =>
    props?.themeStyle?.selectedDayBackground ?? colors.deepBlue};
`

const Day = styled.button<DayProps>`
  flex-grow: 0;
  flex-shrink: 1;
  border-radius: ${props => props?.themeStyle?.dayRadius ?? 0}px;

  flex-basis: ${100 / 7}%;
  max-width: ${100 / 7}%;
  padding: ${({ theme }) => theme.spacing[1.5]};
  cursor: pointer;

  color: ${props => props.themeStyle?.dayColor ?? colors.midGrey};

  ${props => props.selected && selectedDayCSS}

  ${props => props.disabled && disabledDayCSS}

  &:hover {
    ${selectedDayCSS};
  }
`

const getDaysToRenderForMonth = (year: number, month: number) => {
  // Get month to string with a leading zero if needed. 1 -> 01, 10 -> 10, etc.
  const monthLeadingZero = (month + 1).toString().padStart(2, '0')

  // We can't use new Date(year, month, 1) because it will create a date in local time.
  const startOfMonth = new Date(`${year}-${monthLeadingZero}-01T00:00Z`)

  // We can't use startOfMonth in getDaysInMonth because it might use the last day of the previous month.
  // 1st of October could be 30th of September in some timezones.
  const daysInMonth = getDaysInMonth(new Date(year, month))

  const endOfMonth = new Date(
    `${year}-${monthLeadingZero}-${daysInMonth}T00:00Z`
  )

  const distanceFromBeginningOfWeek = startOfMonth.getUTCDay()
  const distanceFromEndOfWeek = 6 - endOfMonth.getUTCDay()

  const daysToGenerate =
    daysInMonth + distanceFromBeginningOfWeek + distanceFromEndOfWeek

  const start = addDays(startOfMonth, -distanceFromBeginningOfWeek)

  return new Array(daysToGenerate)
    .fill(null)
    .map((_, index) => addDays(start, index))
}

const isDayExcluded = (day, excludedDates) => {
  return (
    (excludedDates &&
      excludedDates.some(excludeDate => isSameDay(day, excludeDate))) ||
    false
  )
}

export const CalendarField = ({ ...props }: CalendarFieldProps) => {
  const startDateProp = getFlowValue(props.startDate, props.control)
  const endDateProp = getFlowValue(props.endDate, props.control)
  const startDate = startDateProp ?? new Date()
  const endDate = endDateProp ?? addMonths(startDate, 12)
  const excludedDates = getFlowValue(props.excludedDates, props.control)
  const selectedDate = getFlowValue(props.selected, props.control)
  const isLoading = getFlowValue(props.isLoading, props.control)
  const spinnerColor = getFlowValue(props.spinnerColor, props.control)

  const ariaLabelForFullyBooked = getFlowValue(
    props.ariaLabelForFullyBooked,
    props.control
  )

  const parsedSelectedDate = selectedDate
    ? new Date(
        Date.UTC(
          new Date(selectedDate).getUTCFullYear(),
          new Date(selectedDate).getUTCMonth(),
          new Date(selectedDate).getUTCDate()
        )
      )
    : null
  const dayRef = useRef(null)

  const differenceInMonths = Math.abs(
    differenceInCalendarMonths(startDate, endDate)
  )

  useEffect(() => {
    // Scroll to selected date when opening
    if (selectedDate && dayRef.current) {
      dayRef.current.scrollIntoView({ behaviour: 'smooth', block: 'center' })
    }
  }, [selectedDate])

  const months = useMemo(() => {
    const result: Date[][] = []

    let current = startDate

    for (let i = 0; i <= differenceInMonths; i += 1) {
      const daysInMonth = getDaysInMonth(current)

      result.push([
        ...getDaysToRenderForMonth(current.getFullYear(), current.getMonth()),
      ])

      current = addDays(startOfMonth(current), daysInMonth)
    }

    return result
  }, [])

  const onSelectDay = event => {
    const date = event.target.getAttribute('data-date')

    props.onClick(date, props.control)
  }

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner shouldAnimate color={spinnerColor} />
      </SpinnerWrapper>
    )
  }

  return (
    <>
      {months.map((month, i) => (
        <div
          key={`${month[0].toString()}_month`}
          style={{
            marginLeft: 'calc((-100% / 14) * 0.65)',
            marginTop: i > 0 && 25,
          }}
        >
          <Type
            as="h3"
            preset="headlineMedium"
            weight="bold"
            case="capitalize"
            color={props.themeStyle?.monthColor ?? colors.deepBlue}
            style={{ marginLeft: 'calc((100% / 14) * 0.65)', marginBottom: 12 }}
          >
            {displayDate(month[7], {
              locale: props.control.context.locale,
              withDayOfMonth: false,
              withYear: true,
              displayLongMonth: true,
            })}
          </Type>

          {month.slice(0, 7).map((date, index) => (
            <DayLabel key={`day_label_${index}`}>
              <Type size={{ xs: 12, md: 12 }} weight="bold">
                {displayDate(date, {
                  locale: props.control.context.locale,
                  withDayOfMonth: false,
                  withMonth: false,
                  withWeekday: true,
                  withYear: false,
                })}
              </Type>
            </DayLabel>
          ))}
          <Month>
            {month.map(date => {
              const isDisabled =
                formatDateInUTC(date) < formatDateInUTC(startDate) ||
                date.getUTCMonth() !==
                  month[Math.round(month.length / 2)].getUTCMonth() ||
                isDayExcluded(date, excludedDates) ||
                formatDateInUTC(date) > formatDateInUTC(endDate)

              const isSelected =
                !isDisabled &&
                parsedSelectedDate !== null &&
                formatDateInUTC(date) === formatDateInUTC(parsedSelectedDate)

              return (
                <Day
                  type="button"
                  key={date.toString()}
                  ref={
                    formatDateInUTC(date) ===
                    formatDateInUTC(parsedSelectedDate)
                      ? dayRef
                      : null
                  }
                  onClick={onSelectDay}
                  data-date={date.toISOString()} // Ensure date is passed in UTC
                  disabled={isDisabled}
                  selected={isSelected}
                  themeStyle={props.themeStyle}
                  aria-label={
                    isDisabled && ariaLabelForFullyBooked
                      ? ariaLabelForFullyBooked
                      : `${formatDateInUTC(
                          date,
                          'EEEE, do MMMM',
                          props.control.context.locale
                        )}`
                  }
                >
                  <Type
                    preset="textLarge"
                    duration={0}
                    weight="medium"
                    style={{ pointerEvents: 'none' }}
                  >
                    {date.getUTCDate()}
                  </Type>
                </Day>
              )
            })}
          </Month>
        </div>
      ))}
    </>
  )
}
