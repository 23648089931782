import React from 'react'

import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { FlowControl } from 'bl-flows-core'

import { globalBookingMessages } from '../../messages'
import { TransportationMap } from './TransportationMap'

type MapProps = {
  control: FlowControl
  onSelectLocation?: (TransportationLocation) => void
  isPickup: boolean
  confirmLabel: string
  disclaimer: string
  themeStyle?: PartialBookingEngine['transportationFlow']['map']
}

export const MapContainer = ({
  control,
  onSelectLocation,
  isPickup,
  confirmLabel,
  disclaimer,
  themeStyle,
}: MapProps) => {
  const onConfirm = () => {
    control.nextScreen()
  }
  const removeSelected = () => {
    control.screen.setState({
      location: null,
    })
    control.screen.setUiState({ showMap: false })
  }

  const location = control.screen.stateRef.current.location

  return (
    <TransportationMap
      locationAddress={
        location &&
        control.context.t(globalBookingMessages.text.pleaseWaitAt, {
          location: location.busStop?.name || location.address,
        })
      }
      location={location}
      onConfirm={onConfirm}
      onClose={removeSelected}
      onMarkerSelected={onSelectLocation}
      confirmLabel={confirmLabel}
      isPickup={isPickup}
      disclaimer={disclaimer}
      themeStyle={themeStyle}
    />
  )
}

export default MapContainer
