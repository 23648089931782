import React from 'react'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { Amount } from '../../elements/Amount'
import { CheckmarkIcon } from '../../elements/Icons/CheckmarkIcon'
import { Type } from '../../elements/Typography/Typography'
import { media } from '../../utils/media'

type TextWithIconProps = {
  selected?: boolean
}

const withNewlines = text =>
  typeof text === 'string' ? text.replace(/(.+)(\n|$)/gm, '<p>$1</p>') : null

const styles = {
  Container: styled(({ onClick, children, ...rest }) =>
    onClick ? (
      <button onClick={onClick} {...rest}>
        {children}
      </button>
    ) : (
      <div {...rest}>{children}</div>
    )
  )`
    background: ${colors.white};
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
    color: ${colors.dark};
    display: block;
    position: relative;
    text-align: left;
    width: 100%;
    transition: background 300ms, transform 200ms;

    & + & {
      margin-top: ${({ theme }) => theme.spacing[1.5]};
    }

    ${({ onClick }) =>
      onClick &&
      css`
        cursor: pointer;

        :hover {
          transform: translate(0, -3px);
        }
      `}

    :disabled {
      cursor: default;
      p {
        color: ${colors.midGrey};
        opacity: 0.5;
      }
      :hover {
        transform: none;
      }
    }
  `,
  Content: styled.div`
    padding: ${({ theme }) => `${theme.spacing[2.5]}`};

    ${media.md(css`
      padding: ${({ theme }) => `${theme.spacing[1.5]}`};
    `)}
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  TitlesWrapper: styled.div`
    display: flex;
    white-space: pre-wrap;
  `,
  Footer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing[3]};
  `,
  SelectLabel: styled.div`
    position: absolute;
    bottom: ${({ theme }) => `${theme.spacing[2.5]}`};
    right: ${({ theme }) => `${theme.spacing[2.5]}`};

    ${media.md(css`
      bottom: ${({ theme }) => `${theme.spacing[1.5]}`};
      right: ${({ theme }) => `${theme.spacing[1.5]}`};
    `)}
  `,

  TextWithIcon: styled(Type)<TextWithIconProps>`
    ${({ selected }) =>
      selected &&
      css`
        display: grid;
        grid-template-columns: 17px 1fr;
        gap: 12px;
        align-items: center;
      `}
  `,
}

export const ProductCard = ({
  title,
  titlePostfix = undefined,
  body = undefined,
  footer = true,
  price = undefined,
  priceFormat = undefined,
  priceLabel = undefined,
  rightHeaderLabel = undefined,
  middleHeaderLabel = undefined,
  onClick,
  disabled = undefined,
  ariaLabel = undefined,
  selected = undefined,
  selectLabel = undefined,
  ...rest
}) => {
  return (
    <styles.Container
      onClick={onClick}
      {...rest}
      disabled={disabled}
      aria-label={ariaLabel || title}
    >
      <styles.Content>
        <styles.Header>
          <styles.TitlesWrapper>
            <Type
              preset="label"
              style={{ color: !disabled && colors.deepBlue }}
            >
              {title}
            </Type>
            {titlePostfix && (
              <Type
                preset="label"
                weight="normal"
                style={{ color: !disabled && colors.deepBlue }}
              >
                {titlePostfix}
              </Type>
            )}
          </styles.TitlesWrapper>

          {middleHeaderLabel && <Type preset="text">{middleHeaderLabel}</Type>}
          {rightHeaderLabel && (
            <styles.TextWithIcon
              preset="text"
              color={colors.deepBlue}
              selected={selected}
              weight={selected ? 'bold' : 'normal'}
            >
              {selected && (
                <CheckmarkIcon color={colors.deepBlue} width={17} height={17} />
              )}
              <span>{rightHeaderLabel}</span>
            </styles.TextWithIcon>
          )}
        </styles.Header>
        {body && (
          <Type
            preset="text"
            top={{ xs: 1 }}
            dangerouslySetInnerHTML={{
              __html: withNewlines(body),
            }}
          />
        )}
        {footer && (
          <styles.Footer>
            {price && (
              <Type preset="label" style={{ flex: '1 0' }}>
                <Amount value={price} useSymbol format={priceFormat} />
              </Type>
            )}
            {!price && priceLabel && (
              <Type preset="tiny" case="uppercase">
                {priceLabel}
              </Type>
            )}
          </styles.Footer>
        )}
        {selectLabel && (
          <styles.SelectLabel>
            <Type
              preset="label"
              color={colors.deepBlue}
              weight="normal"
              style={{ marginLeft: 'auto' }}
            >
              {selectLabel}
            </Type>
          </styles.SelectLabel>
        )}
      </styles.Content>
    </styles.Container>
  )
}
