import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { useTheme } from 'styled-components'

import { BookingEngineScreenThemes } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'

import { renderFields } from '../../renderers/renderUtils'
import {
  FlowDoubleLayout,
  FlowDoubleLayoutFields,
  FlowLayoutComponent,
} from '../../types'

type DoubleLayoutProps = FlowLayoutComponent<FlowDoubleLayoutFields> &
  FlowDoubleLayout['props']

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  [mediaObj.md]: {
    flexDirection: 'row',
  },
})

export const ContentContainer = styled(motion.div)<{
  screentheme?: string
  screenthemes: BookingEngineScreenThemes
  hideOnMobile?: boolean
}>(({ screentheme, screenthemes, hideOnMobile }) => ({
  display: hideOnMobile ? 'none' : 'flex',
  flex: '0 1 50%',
  flexDirection: 'column',
  width: '100%',
  overflowY: 'auto',
  height: '100%',

  minHeight: '100%',
  [mediaObj.md]: { display: 'flex' },
  [mediaObj.bmd]: { width: '50%' },

  background:
    (screentheme &&
      screenthemes[screentheme] &&
      screenthemes[screentheme].background) ||
    'inherit',
  color:
    (screentheme &&
      screenthemes[screentheme] &&
      screenthemes[screentheme].color) ||
    'inherit',
}))

const layoutAnimation = {
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  initial: { y: 15, opacity: 0 },
}

export const DoubleLayout: React.FC<DoubleLayoutProps> = ({
  screenTheme,
  fields,
  control,
}) => {
  const theme = useTheme()
  const screenThemes = theme?.bookingEngineScreenThemes

  return (
    <Wrapper>
      <ContentContainer
        {...layoutAnimation}
        screentheme={screenTheme}
        screenthemes={screenThemes}
      >
        {renderFields(fields.main, control, screenTheme)}
      </ContentContainer>
      <ContentContainer
        hideOnMobile
        {...layoutAnimation}
        screentheme={screenTheme}
        screenthemes={screenThemes}
      >
        {renderFields(fields.right, control, screenTheme)}
      </ContentContainer>
    </Wrapper>
  )
}
