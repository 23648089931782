import React from 'react'

import { colors } from '../constants/colors'

export const SmallArrow = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4252 4.06816L10.9293 3.59215L7.81456 0.559258C7.54219 0.29405 7.10221 0.29405 6.82984 0.559258C6.55748 0.824465 6.55748 1.25288 6.82984 1.51809L9.24625 3.87096H1.152C0.767885 3.87096 0.453613 4.17697 0.453613 4.55098C0.453613 4.92499 0.767885 5.231 1.152 5.231H9.24625L6.82984 7.58387C6.55748 7.84908 6.55748 8.27749 6.82984 8.5427C7.10221 8.8079 7.54219 8.8079 7.81456 8.5427L10.9293 5.50981L11.4182 5.03379C11.6976 4.76178 11.6976 4.33337 11.4252 4.06816Z"
        fill={'currentColor' || colors.deepBlue}
      />
    </svg>
  )
}
