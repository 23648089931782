import React from 'react'
import { useTheme } from 'styled-components'

import { ProgressiveImage } from 'bl-common/src/elements/ProgressiveImage'

import { renderFields } from '../../renderers/renderUtils'
import {
  FlowField,
  FlowImageLayout,
  FlowImageLayoutFields,
  FlowLayoutComponent,
} from '../../types'
import type { AtLeastOne } from '../../types/typeUtils'
import {
  Container,
  ImageContainer,
  layoutAnimation,
  LeftContainer,
  RightContainer,
} from './styles'

type ImageLayoutProps = FlowLayoutComponent<FlowImageLayoutFields> &
  FlowImageLayout['props']

export const ImageLayout: React.FC<ImageLayoutProps> = ({
  layoutImageSrc = 'https://images.ctfassets.net/w65k7w0nsb8q/6Pd8n9YQMlWGofxkXzNcaN/b48f5146de4882011387b4f75e95768d/IMG_0963.jpg',
  screenTheme,
  fields,
  control,
  style,
  columnStyle = false,
  leftColumnWidth = 50,
}) => {
  const theme = useTheme()
  const screenThemes = theme?.bookingEngineScreenThemes

  return (
    <Container>
      <LeftContainer
        {...layoutAnimation}
        screentheme={screenTheme}
        screenthemes={screenThemes}
        desktopwidth={leftColumnWidth}
        style={style}
      >
        {renderFields(fields.main, control, screenTheme, false, columnStyle)}
      </LeftContainer>
      <RightContainer desktopwidth={100 - leftColumnWidth}>
        <ImageContainer>
          <ProgressiveImage image={layoutImageSrc} />
        </ImageContainer>
        {fields.right &&
          fields.right.length > 0 &&
          renderFields(
            fields.right as AtLeastOne<FlowField>,
            control,
            screenTheme,
            false,
            columnStyle
          )}
        {fields.rightBottom && (
          <div
            style={{
              marginTop: 'auto',
            }}
          >
            {renderFields(
              fields.rightBottom,
              control,
              screenTheme,
              false,
              columnStyle
            )}
          </div>
        )}
      </RightContainer>
    </Container>
  )
}
