import { defineMessages } from 'react-intl'

const info = defineMessages({
  massageCartTitle: {
    id: 'sharedFlows:cart.massageCartTitle',
    defaultMessage: 'Relaxing in-water massage',
    description: 'Activities: Massage title for cart',
  },
  signatureMassageCartTitle: {
    id: 'sharedFlows:cart.signatureMassageCartTitle',
    defaultMessage: 'Signature in-water massage',
    description: 'Activities: Signature massage title for cart',
  },
  massage30CartSubtitle: {
    id: 'sharedFlows:cart.massage30CartSubtitle',
    defaultMessage: '30 min relaxing massage',
    description: 'Activities: 30 min massage subtitle for cart',
  },
  massage60CartSubtitle: {
    id: 'sharedFlows:cart.massage60CartSubtitle',
    defaultMessage: '60 min relaxing massage',
    description: 'Activities: 60 min massage subtitle for cart',
  },
  massage120CartSubtitle: {
    id: 'sharedFlows:cart.massage120CartSubtitle',
    defaultMessage: '120 min relaxing massage',
    description: 'Activities: 120 min massage subtitle for cart',
  },
  cartGuests: {
    id: 'sharedFlows:cart.massageCartGuests',
    defaultMessage: ' guests',
    description: 'Activities: Guests label for cart',
  },
  singleFloatCartTitle: {
    id: 'sharedFlows:cart.singleFloatCartTitle',
    defaultMessage: 'Float Therapy',
    description: 'Activities: Floating for 1 title for cart',
  },
  couplesFloatCartTitle: {
    id: 'sharedFlows:cart.couplesFloatCartTitle',
    defaultMessage: 'Couples Float Therapy',
    description: 'Activities: Floating for 2 title for cart',
  },
  couplesFloatCartSubtitle: {
    id: 'sharedFlows:cart.couplesFloatCartSubtitle',
    defaultMessage: '45 min',
    description: 'Activities: 45 min Couples Float Therapy subtitle for cart',
  },
  groupFloatCartTitle: {
    id: 'sharedFlows:cart.groupFloatCartTitle',
    defaultMessage: 'Group Float Therapy',
    description: 'Activities: Group float title for cart',
  },
  lavaCartTitle: {
    id: 'sharedFlows:cart.lavaCartTitle',
    defaultMessage: 'Lava Table Reservation',
    description: 'Activities: Lava title for cart',
  },
  spaRestaurantCartTitle: {
    id: 'sharedFlows:cart.spaRestaurantCartTitle',
    defaultMessage: 'Spa Restaurant Table Reservation',
    description: 'Activities: Spa restaurant title for cart',
  },
  highlandBaseRestaurantCartTitle: {
    id: 'sharedFlows:cart.highlandBaseRestaurantCartTitle',
    defaultMessage: 'Restaurant Table Reservation',
    description: 'Activities: Highland Base Restaurant title for cart',
  },
  privateTransferSmallTitle: {
    id: 'sharedFlows:cart.privateTransferSmallTitle',
    defaultMessage: 'Private transfer for 1-3 guests',
    description: 'Activities: Private transfer for 1-3 guests title for cart',
  },
  privateTransferLargeTitle: {
    id: 'sharedFlows:cart.privateTransferLargeTitle',
    defaultMessage: 'Private transfer for 4-8 guests',
    description: 'Activities: Private transfer for 4-8 guests title for cart',
  },
  privateTransferServioSmallTitle: {
    id: 'sharedFlows:cart.privateTransferServioSmallTitle',
    defaultMessage: 'Private transfer for 1-2 guests',
    description:
      'Activities: Private transfer Servio for 1-2 guests title for cart',
  },
  privateTransferServioMediumTitle: {
    id: 'sharedFlows:cart.privateTransferServioMediumTitle',
    defaultMessage: 'Private transfer for 1-4 guests',
    description:
      'Activities: Private transfer Servio for 1-4 guests title for cart',
  },
  privateTransferServioLargeTitle: {
    id: 'sharedFlows:cart.privateTransferServioLargeTitle',
    defaultMessage: 'Private transfer for 1-9 guests',
    description:
      'Activities: Private transfer Servio for 1-9 guests title for cart',
  },
  transportationTitle: {
    id: 'sharedFlows:cart.transportationTitle',
    defaultMessage: 'Bus transfer',
    description: 'Activities: Transportation title for cart',
  },
  transportationFromLocation: {
    id: 'sharedFlows:cart.transportationFromLocation',
    defaultMessage: 'From {location}',
    description: 'Activities: transportation from location label for cart',
  },
  transportationToLocation: {
    id: 'sharedFlows:cart.transportationToLocation',
    defaultMessage: 'To {location}',
    description: 'Activities: transportation to location label for cart',
  },
  transportationReykjavikToHighlandBase: {
    id: 'sharedFlows:cart.transportationReykjavikToHighlandBase',
    defaultMessage: 'Super Jeep from Skogarhlid to Highland Base',
    description: 'Activities: transportation from Reykjavik to Highland Base',
  },
  transportationHighlandBaseToReykjavik: {
    id: 'sharedFlows:cart.transportationHighlandBaseToReykjavik',
    defaultMessage: 'Super Jeep from Highland Base to Skogarhlid',
    description: 'Activities: transportation from Highland Base to Reykjavik',
  },
  transportationSkjolToHighlandBase: {
    id: 'sharedFlows:cart.transportationSkjolToHighlandBase',
    defaultMessage: 'Super Jeep from Skjól to Highland Base',
    description: 'Activities: transportation from Skjól to Highland Base',
  },
  transportationHighlandBaseToSkjol: {
    id: 'sharedFlows:cart.transportationHighlandBaseToSkjol',
    defaultMessage: 'Super Jeep from Highland Base to Skjól',
    description: 'Activities: transportation from Highland Base to Skjól',
  },
})

const disclaimers = defineMessages({
  busPickupDisclaimer: {
    id: 'sharedFlows:cart.busPickupDisclaimer',
    defaultMessage: 'Pickup 30 minutes prior',
    description: 'Transportation: Bus pickup disclaimer',
  },
})

export const cartMessages = {
  info,
  disclaimers,
}
