import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { is } from 'date-fns/locale/is'
import { darken } from 'polished'
import styled, { css } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { modularScale } from 'bl-common/src/constants/sizes'
import { NavArrow } from 'bl-common/src/elements/Arrow/Arrow'
import { between } from 'bl-common/src/utils/between'
import { media } from 'bl-common/src/utils/media'
// TODO fix not being able to import: https://github.com/vercel/next.js/issues/19936
// import 'react-datepicker/dist/react-datepicker.css'
import { displayDate } from 'bl-utils/src/formatting/formatDate'

import { FlowDatePickerField, FlowInputComponent } from '../../types'
import { getFlowValue } from '../../utils'

registerLocale('is', is)

type DateRangePickerFieldProps = FlowInputComponent &
  FlowDatePickerField['props'] & {
    id: string
    locale?: 'is' | 'en'
  }
type DateRangePickerWrapperProps = Pick<FlowDatePickerField['props'], 'layout'>

const Day = styled.span<DateRangePickerWrapperProps>`
  ${({ layout }) =>
    layout === 'horizontal'
      ? css`
          font-size: 12px;
        `
      : css`
          font-size: 18px;

          ${media.md(css`
            font-size: ${between(12 / modularScale, 18)};
          `)}
        `}
`

const DatePickerWrapper = styled.div<DateRangePickerWrapperProps>`
  .react-datepicker {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
    line-height: 1.125em;
    padding: 0;
    border: none;
    color: #454647;
    border-radius: 0;

    ${({ layout }) =>
      layout === 'horizontal' &&
      media.md(css`
        flex-direction: row;
      `)}
  }

  .react-datepicker__month-container {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing[2]};

    ${({ layout }) =>
      layout === 'horizontal' &&
      media.md(css`
        width: 50%;

        &:nth-child(odd) {
          padding-left: ${({ theme }) => theme.spacing[2]};
        }
        &:nth-child(even) {
          padding-right: ${({ theme }) => theme.spacing[2]};
        }
      `)}
  }

  .react-datepicker__header {
    background: ${colors.white};
    border: none;
    text-align: left;

    ${({ layout }) =>
      layout === 'horizontal' &&
      media.md(css`
        text-align: center;
      `)}
  }

  .react-datepicker__navigation-wrapper {
    margin-bottom: ${({ theme }) => theme.spacing[1]};

    ${media.md(css`
      margin-bottom: ${({ theme }) => theme.spacing[1]};
    `)}
  }

  .react-datepicker__navigation--previousButton,
  .react-datepicker__navigation--nextButton {
    display: none;
    ${({ layout }) =>
      layout === 'horizontal' &&
      media.md(css`
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      `)}
  }

  .react-datepicker__navigation {
  }

  .react-datepicker__navigation--previousButton {
    margin-left: -4px;
  }
  .react-datepicker__navigation--nextButton {
    margin-right: -4px;
  }
  .react-datepicker__day-names,
  .react-datepicker__week,
  .react-datepicker__navigation-wrapper {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: space-between;
  }
  .react-datepicker__day-name {
    text-transform: uppercase;
    padding: 0.5em;
    font-weight: bold;
    width: 100%;
    opacity: 0.75;
    color: #454647;
    margin: 0;

    text-align: center;

    ${({ layout }) =>
      layout === 'horizontal'
        ? css`
            font-size: 12px;
          `
        : css`
            font-size: 12px;

            ${media.md(css`
              font-size: ${between(10 / modularScale, 12)};
            `)}
          `}
  }

  .react-datepicker__day {
    min-height: 40px;
    min-width: 40px;
    width: 100%;
    padding: 0.5em;
    margin: 0;
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #454647;

    &:hover {
      border-radius: 0;
      background-color: ${colors.lightGrey};
    }
  }

  .react-datepicker__day--outside-month {
    opacity: 0;
  }

  .react-datepicker__month {
    text-align: center;
    margin: 0;

    ${({ layout }) =>
      layout === 'horizontal' &&
      media.md(css`
        text-align: center;
      `)}
  }

  .react-datepicker__current-month {
    font-weight: 700;
    color: ${({ layout }) =>
      layout === 'horizontal' ? colors.midGrey : colors.deepBlue};
    pointer-events: none;
    margin: 0.166em;
    padding: 0.5em;
    grid-column: 1 / span 7;

    ${({ layout }) =>
      layout === 'horizontal'
        ? css`
            font-size: 14px;
            ${media.md(css`
              font-size: 20px;
              padding: 0;
              margin: 0;
              grid-column: 2 / span 5;
            `)}
          `
        : css`
            font-size: 18px;

            ${media.md(css`
              font-size: ${between(12 / modularScale, 30)};
            `)}
          `}
  }

  .react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    border-radius: 0%;
    background-color: ${colors.deepBlue};
    color: ${colors.white};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    border-radius: 0%;
    background-color: ${colors.white};
    color: #454647;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: ${colors.lightGrey};
    border-radius: 0%;
    color: #454647;
  }
  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
  .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
    border-radius: 0%;
    background-color: ${colors.fountainBlue};
    color: ${colors.white};
  }

  .react-datepicker__day--disabled {
    pointer-events: none;
    opacity: 0.3;

    &:hover {
      border-radius: 0;
      background-color: ${colors.white};
    }
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range {
    border-radius: 0%;
    color: ${colors.white};
    background-color: ${colors.deepBlue};
    &:hover {
      border-radius: 0%;
    }
  }

  .react-datepicker__day--in-range {
    color: ${colors.white};
    background: ${colors.fountainBlue};
    &:hover {
      background: ${colors.deepBlue};
    }
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    background: ${colors.deepBlue};
    color: ${colors.white};

    &:hover {
      color: ${colors.white};
      background: ${darken(0.05, colors.deepBlue)};
    }
  }
`
export const DatePickerField = ({
  startDate,
  endDate,
  onChange,
  monthsShown = 12,
  layout = 'horizontal',
  locale = 'en',
  ...props
}: DateRangePickerFieldProps) => {
  const _onChange = (value: Date | Date[]) => {
    if (Array.isArray(value)) {
      onChange?.(value, props.control)
    } else {
      onChange?.([value], props.control)
    }
  }

  return (
    <DatePickerWrapper layout={layout}>
      <DatePicker
        locale={locale}
        minDate={getFlowValue(props.minDate, props.control)}
        maxDate={getFlowValue(props.maxDate, props.control)}
        selected={getFlowValue(startDate, props.control)}
        onChange={_onChange}
        startDate={getFlowValue(startDate, props.control)}
        endDate={getFlowValue(endDate, props.control)}
        selectsRange
        selectsDisabledDaysInRange
        inline
        monthsShown={monthsShown}
        renderCustomHeader={({
          monthDate,
          customHeaderCount,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div className={'react-datepicker__navigation-wrapper'}>
            <button
              aria-label="Previous Month"
              className={'react-datepicker__navigation--previousButton'}
              style={customHeaderCount === 1 ? { visibility: 'hidden' } : null}
              onClick={decreaseMonth}
            >
              <NavArrow rotate={0} scale={1} color={colors.deepBlue} />
            </button>
            <span className="react-datepicker__current-month">
              {displayDate(monthDate, {
                locale: props.control.context.locale,
                withYear: false,
                withDayOfMonth: true,
                withMonth: true,
                displayLongMonth: false,
                displayLongWeekday: true,
              })}
            </span>
            <button
              aria-label="Next Month"
              className={'react-datepicker__navigation--nextButton'}
              style={customHeaderCount === 0 ? { visibility: 'hidden' } : null}
              onClick={increaseMonth}
            >
              <NavArrow rotate={180} scale={1} color={colors.deepBlue} />
            </button>
          </div>
        )}
        renderDayContents={day => {
          return <Day layout={layout}>{day}</Day>
        }}
      />
    </DatePickerWrapper>
  )
}
