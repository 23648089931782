import React, { useContext, useEffect, useState } from 'react'
import { isAfter } from 'date-fns/isAfter'
import { isSameDay } from 'date-fns/isSameDay'
import { sub } from 'date-fns/sub'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { CloseIcon } from 'bl-common/src/elements/Icons/CloseIcon'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { SimpleRadio } from 'bl-common/src/form/Radio/SimpleRadio'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine, ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildDateTimeSelectField,
  buildDisclaimerField,
  buildInputText,
  FieldRenderer,
  FlowControl,
  SubFlowActivity,
  SubFlowBookingInfo,
} from 'bl-flows-core'
import {
  MassageAndFloatAvailabilityQuery,
  useMassageAndFloatAvailabilityQuery,
} from 'bl-graphql/src/generated/hooks-with-types'
import { productIdToName } from 'bl-utils/src/analytics'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'

import { globalBookingMessages, productIdsMessages } from '../messages'
import { MassagesState, MassageState } from '../subflows'
import { floatMessages } from '../subflows/float/messages/floatMessages'
import { massageMessages } from '../subflows/massage/messages/massageMessages'
import { item_list_id, item_list_name } from '../utils/analytics'
import { filterInWaterSlots } from '../utils/filterInWaterSlots'
import { getBookingDates } from '../utils/getBookingDates'

type MassageCardProps = {
  number: number
  control: FlowControl
  bookingInfo: SubFlowBookingInfo
  previousMassageActivity?: SubFlowActivity
  isEditing?: boolean
  enableClearCard?: boolean
  isFloat?: boolean
  themeStyle?: PartialBookingEngine['massageCard']
  screenTheme?: ScreenTheme
  isMYB?: boolean
  onDeleteCard?: (index: number) => void
}

const DropdownSvg = ({
  color,
}: {
  color?: PartialBookingEngine['massageCard']['cardHeadingColor']
}) => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1L5 5L1 1"
      stroke={color || colors.deepBlue}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Card = styled(motion.div)<{
  $themeStyle?: PartialBookingEngine['massageCard']
}>(({ $themeStyle }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing[1.5],
  boxShadow: $themeStyle?.boxShadow ?? '0px 8px 24px rgba(0, 0, 0, 0.1)',
  background: $themeStyle?.cardBackground ?? colors.white,
}))

const CardHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing[1.5],
})

const ClearButton = styled.button<{ $color?: string }>(({ $color }) => ({
  fontWeight: 'bold',
  textDecoration: 'underline',
  color: $color ?? colors.deepBlue,
  cursor: 'pointer',
}))

const RadioButtonWrapper = styled.div<{ $hide: boolean }>(props => ({
  marginBottom: theme.spacing[1],
  display: props.$hide ? 'none' : 'block',
}))

const RadioOptionWrapper = styled.div({
  display: 'flex',
  gap: theme.spacing[0.5],
  alignItems: 'center',
})

const RadioAndDescription = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const RadioButtonPrice = styled.span({
  display: 'inline-block',
})

type FilteredSlots =
  MassageAndFloatAvailabilityQuery['massageAndFloatAvailability'][
    | 'floats'
    | 'massages'][number]['slots']

export const MassageCard = ({
  number,
  control,
  bookingInfo,
  previousMassageActivity,
  isEditing,
  enableClearCard,
  isFloat,
  themeStyle,
  screenTheme,
  isMYB,
  onDeleteCard,
}: MassageCardProps) => {
  const [openDetails, setOpenDetails] = useState<string | undefined>()
  const [filteredSlots, setFilteredSlots] = useState<FilteredSlots>([])
  const [hasViewItemListTriggered, setHasViewItemListTriggered] =
    useState(false)
  const isRetreatSpaBooking = control.flow.setupHook?.isRetreatSpaBooking
  // Group float should only be available for non-retreat bookings
  const enableGroupFloat =
    control.flow.setupHook?.enableAddGroupFloat &&
    !isRetreatSpaBooking &&
    bookingInfo?.property !== 'retreat'

  const { currency, exchangeRates } = useContext(CurrencyContext)

  const currentMassage: MassageState =
    control.screen.stateRef?.current?.massages?.[number]

  const defaultDate = currentMassage?.date
    ? new Date(currentMassage?.date)
    : bookingInfo?.entryDate || bookingInfo?.hotelDates?.arrivalDate

  const [date, setDate] = useState<string>(formatDateInUTC(defaultDate))

  const isHotelBooking = !!bookingInfo?.hotelDates

  // This array is also used to order the floats in the UI
  const SUPPORTED_FLOAT_TYPES = [
    PRODUCT_IDS.MassageFloatingOne,
    PRODUCT_IDS.MassageFloatingTwo,
    PRODUCT_IDS.MassageFloatingMidnight,
    ...(enableGroupFloat ? [PRODUCT_IDS.MassageFloatingGroup] : []),
  ] as const

  type SupportedFloatTypes = typeof SUPPORTED_FLOAT_TYPES[number]

  const isCoupleFloat = isEditing
    ? previousMassageActivity?.productNo === PRODUCT_IDS.MassageFloatingTwo
    : currentMassage?.productNo === PRODUCT_IDS.MassageFloatingTwo

  const dates = getBookingDates(bookingInfo)

  const filteredDates = dates.filter(date => {
    const yesterday = sub(new Date(), { days: 1 })
    // We want to allow customers to book activities on the entry date/checkout date
    return isAfter(date, yesterday)
  })

  // Setting nextFetchPolicy to no-cache since it can interfere with the cache
  // of the other queries, e.g. if you are booking multiple massages.
  const { data, loading } = useMassageAndFloatAvailabilityQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'no-cache',
    variables: {
      locale: control.context.locale,
      date,
      arrivalTime: bookingInfo?.entryDate
        ? formatDateInUTC(bookingInfo?.entryDate, 'HH:mm')
        : '07:00',
    },
    skip: !date,
    onCompleted: () => {
      if (currentMassage?.productNo) {
        const slots = filterInWaterSlots({
          massageType: currentMassage?.productNo,
          ...filterSlotsOptions,
        })
        setFilteredSlots(slots)
        // This setState runs for example when the user changes the date,
        // and has already selected productNo(massage type).
        // It sets the time to the currently selected time,
        // if there is one and resets to first slot if not(for example on date change)

        control.screen.setState<MassagesState>({
          [`massages[${number}]`]: {
            ...currentMassage,
            time: currentMassage.time || slots[0]?.time,
          },
        })
      }
      // Initialize the state with the previous activity if the user is editing
      if (
        isEditing &&
        previousMassageActivity &&
        !control.screen.state.massages?.[number]?.massageInfo
      ) {
        const coupleFloatGuestName1 = previousMassageActivity?.comment
          ?.split(',name2: ')
          ?.slice(0)?.[0]
          ?.replace('name1: ', '')

        const coupleFloatGuestName2 = previousMassageActivity?.comment
          ?.split(',name2: ')
          ?.slice(-1)?.[0]

        control.screen.setState<MassagesState>({
          [`massages[${number}]`]: {
            productNo: previousMassageActivity.productNo,
            guestName: !isCoupleFloat
              ? previousMassageActivity?.comment?.replace('guestName: ', '')
              : coupleFloatGuestName1,
            ...(isCoupleFloat && {
              guestName2: coupleFloatGuestName2,
            }),
            title: previousMassageActivity.title,
            quantity: previousMassageActivity.quantity,
            massageInfo: massages?.find(
              massage => massage.id === previousMassageActivity.productNo
            ),
            // Set the initial date by finding a date option that matches the previously booked date
            // That way the date option will be preselected with what was previously booked.
            // The date can't simply be previousMassageActivity.date because the date options hours
            // are set to the hotel check-in hours which won't necessarily match the massage hours
            date: dates.find(dateOption =>
              isSameDay(dateOption, new Date(previousMassageActivity.date))
            ),
            time: getTimeFromDateTimeString(previousMassageActivity.date),
          },
        })
      }
    },
  })

  const filterSlotsOptions = {
    control,
    availability: isFloat
      ? data?.massageAndFloatAvailability?.floats
      : data?.massageAndFloatAvailability?.massages,
    bookingInfo,
    date,
    number,
    isRetreatSpaBooking,
  }

  const massages = isFloat
    ? data?.massageAndFloatAvailability?.floats
        .filter(float =>
          SUPPORTED_FLOAT_TYPES.includes(float.id as SupportedFloatTypes)
        )
        // Sort floats into the given order so the float options(radio)
        // are displayed in the correct order.
        .sort(
          (a, b) =>
            SUPPORTED_FLOAT_TYPES.indexOf(a.id as SupportedFloatTypes) -
            SUPPORTED_FLOAT_TYPES.indexOf(b.id as SupportedFloatTypes)
        )
    : data?.massageAndFloatAvailability?.massages?.filter(massage => {
        return (
          massage.id === PRODUCT_IDS.Massage30 ||
          massage.id === PRODUCT_IDS.Massage60 ||
          // Signature massage is not allowed in MYB until they have Planet Pay
          (!isMYB && massage.id === PRODUCT_IDS.Massage120)
        )
      })

  const getMassageDuration = (productId: string) => {
    return massages?.find(massage => massage.id === productId)?.duration || 45
  }

  const massageMessagesMap = {
    [PRODUCT_IDS.Massage30]: {
      description: control.context.t(massageMessages.info.massage30Description),
    },
    [PRODUCT_IDS.Massage60]: {
      description: control.context.t(massageMessages.info.massage60Description),
    },
    [PRODUCT_IDS.Massage120]: {
      description: control.context.t(
        massageMessages.info.massage120Description
      ),
    },
    [PRODUCT_IDS.MassageFloatingOne]: {
      description: control.context.t(
        massageMessages.info.singleFloatDescription,
        {
          duration: getMassageDuration(PRODUCT_IDS.MassageFloatingOne),
        }
      ),
    },
    [PRODUCT_IDS.MassageFloatingTwo]: {
      description: control.context.t(
        massageMessages.info.couplesFloatDescription,
        { duration: getMassageDuration(PRODUCT_IDS.MassageFloatingTwo) }
      ),
    },
    [PRODUCT_IDS.MassageFloatingGroup]: {
      description: control.context.t(
        massageMessages.info.groupFloatDescription,
        { duration: getMassageDuration(PRODUCT_IDS.MassageFloatingGroup) }
      ),
    },
  }

  const onChooseMassage = (productNo: string) => {
    const slots = filterInWaterSlots({
      massageType: productNo,
      ...filterSlotsOptions,
    })
    setFilteredSlots(slots)

    // When the user picks a massage type, we preselect the first available slot.
    control.screen.setState<MassagesState>({
      [`massages[${number}]`]: {
        ...currentMassage,
        productNo,
        selectedIndex: massages.findIndex(m => m.id === productNo),
        massageInfo: massages.find(m => m.id === productNo),
      },
    })
  }

  const onChangeDate = (newDate: string) => {
    setDate(formatDateInUTC(newDate))
    // Reset the selected time if user changes the date
    control.screen.setState<{ [x: string]: MassageState }>({
      [`massages[${number}]`]: {
        ...currentMassage,
        time: null,
      },
    })
  }

  // We need to re-filter slots if another massage picks a time,
  // because we "deduct"(fake reserve) the slots that other massage cards select.
  // This effect runs when the massages state changes, and sets re-filtered slots
  useEffect(() => {
    if (!filteredSlots) {
      return
    }
    const newFilteredSlots = filterInWaterSlots({
      massageType: currentMassage?.productNo,
      ...filterSlotsOptions,
    })
    setFilteredSlots(newFilteredSlots)
  }, [JSON.stringify(control.screen.stateRef?.current?.massages)])

  useEffect(() => {
    if (massages && !isMYB && !hasViewItemListTriggered) {
      setHasViewItemListTriggered(true)

      triggerEvent({
        event: 'view_item_list',
        ecommerce: {
          item_list_id: isFloat ? 'float' : 'massage',
          item_list_name: isFloat ? 'Float' : 'Massage',

          items: massages.map((massage, index) => {
            return {
              item_id: massage.id,
              item_name: productIdToName[massage.id],
              currency: 'EUR',
              index,
              item_brand: 'BLue Lagoon',
              item_category: isHotelBooking ? 'Accommodation' : 'Day Visit',
              item_list_id: item_list_id({
                productId: massage.id,
                isHotelBooking,
                isFloat,
              }),
              item_list_name: item_list_name({
                productId: massage.id,
                isHotelBooking,
                isFloat,
              }),
              price:
                massage.price > 0
                  ? calcPrice(massage.price, exchangeRates?.EUR)
                  : 0,
              quantity: 1,
            }
          }),
        },
      })
    }
  }, [massages])

  const onClearMassage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    control?.screen?.setState<MassagesState>({
      [`massages[${number}]`]: {
        date: currentMassage?.date,
      },
    })
  }

  const showMassageTypeOptions = !!massages?.length && !isEditing
  const noAvailableMassagesForTypeAndDate =
    currentMassage?.massageInfo?.available === 0
  const hasAvailableMassages =
    massages?.length && !noAvailableMassagesForTypeAndDate
  const showClearButton = enableClearCard && currentMassage?.massageInfo

  return (
    <Card
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      $themeStyle={themeStyle}
    >
      <CardHeader>
        <Type
          preset="textLarge"
          weight="bold"
          color={themeStyle?.cardHeadingColor ?? colors.deepBlue}
        >
          {isEditing && previousMassageActivity
            ? previousMassageActivity?.title
            : control.context.t(
                isFloat
                  ? floatMessages.info.timeCardHeading
                  : massageMessages.info.timeCardHeading,
                {
                  number: number + 1,
                }
              )}
        </Type>
        {showClearButton ? (
          <ClearButton
            onClick={onClearMassage}
            $color={themeStyle?.clearButtonColor}
          >
            {control.context.t(massageMessages.info.clear)}
          </ClearButton>
        ) : (
          <>
            {number !== 0 && isFloat && (
              <Type
                as="button"
                type="button"
                preset="textLarge"
                onClick={() => onDeleteCard(number)}
                style={{ cursor: 'pointer' }}
              >
                <CloseIcon
                  style={{ height: '1.3ch' }}
                  color={themeStyle?.deleteButtonColor ?? colors.darkmode}
                />
              </Type>
            )}
          </>
        )}
      </CardHeader>
      {showMassageTypeOptions && (
        <RadioButtonWrapper $hide={isEditing}>
          {massages.map(massage => (
            <RadioAndDescription key={massage.id}>
              <RadioOptionWrapper>
                <SimpleRadio
                  key={massage.id}
                  id={`${massage.id}${number}`}
                  label={
                    isMYB ? (
                      <>
                        {control.context.t(productIdsMessages[massage.id])}{' '}
                        <RadioButtonPrice>
                          {formatPrice(massage.price, 'ISK', false)}
                        </RadioButtonPrice>
                      </>
                    ) : (
                      <>
                        {control.context.t(productIdsMessages[massage.id])}{' '}
                        <RadioButtonPrice>
                          {formatPrice(
                            calcPrice(massage.price, exchangeRates?.[currency]),
                            currency,
                            false
                          )}
                        </RadioButtonPrice>
                      </>
                    )
                  }
                  name={`massageChoice${number}`}
                  value={massage.id}
                  checked={currentMassage?.productNo === massage.id}
                  onChange={event => onChooseMassage(event.target.value)}
                  preset="text"
                  themeStyle={themeStyle?.radio}
                />

                {/* Only show the dropdown button if there is an available description */}
                {!!massageMessagesMap[massage.id] && (
                  <button
                    type="button"
                    style={{
                      cursor: 'pointer',
                      padding: theme.spacing[0.5],
                      transition: 'transform 150ms ease-in-out',
                      transform:
                        openDetails === massage.id && 'rotate(-180deg)',
                    }}
                    onClick={() =>
                      setOpenDetails(
                        openDetails === massage.id ? undefined : massage.id
                      )
                    }
                  >
                    <DropdownSvg color={themeStyle?.cardHeadingColor} />
                  </button>
                )}
              </RadioOptionWrapper>
              <AnimatePresence exitBeforeEnter>
                {openDetails === massage.id && (
                  <motion.div
                    key={massage.id}
                    initial={{ height: 0, overflow: 'hidden' }}
                    animate={{ height: 'auto', overflow: 'auto' }}
                    exit={{ height: 0, overflow: 'hidden' }}
                    transition={{ duration: 0.3 }}
                  >
                    <Type
                      style={{
                        paddingBottom: theme.spacing[1],
                        paddingTop: theme.spacing[0.5],
                        marginLeft: `calc(${theme.spacing[1]} + 18px)`,
                      }}
                      preset="text"
                    >
                      {massageMessagesMap[massage.id].description}
                    </Type>
                  </motion.div>
                )}
              </AnimatePresence>
            </RadioAndDescription>
          ))}
        </RadioButtonWrapper>
      )}
      {control.screen.uiState?.massages?.[number]?.error && (
        <Type color={colors.deepRed}>
          {control.screen.uiState?.massages?.[number]?.error}
        </Type>
      )}
      {loading && <Spinner shouldAnimate />}

      <FieldRenderer
        control={control}
        item={buildDisclaimerField({
          props: {
            value: control.context.t(
              massageMessages.errors.noAvailabilityError
            ),
            color: colors.errorRed,
            preset: 'text',
            showDisclaimer: !loading && !hasAvailableMassages,
          },
        })}
      />

      {/* disclaimer for if there are already activities for all guests
       at the same time as the currently chosen massage/float time */}
      <FieldRenderer
        control={control}
        screenTheme={screenTheme}
        item={buildDisclaimerField({
          props: {
            value: control.context.t(
              globalBookingMessages.disclaimers.allGuestsBusyDisclaimer
            ),
            color: colors.errorRed,
            preset: 'text',
            showDisclaimer: control => control.screen.setupHook?.allGuestsBusy,
          },
        })}
      />
      <FieldRenderer
        control={control}
        screenTheme={screenTheme}
        item={buildDateTimeSelectField({
          id: `massages[${number}]`,
          defaultValue: null,
          props: {
            dates: filteredDates,
            timeSlots: filteredSlots,
            multiple: true,
            guests: bookingInfo.adults + bookingInfo.children,
            // For floats and massages, we can book 1 activity per guest
            bookableQuantity: 1,
            skipOwnError: true,
            currentReservationDate: previousMassageActivity?.date,
            showCurrentReservationLabel: isEditing,
            // Need to pass down a reference to the massages[number] state,
            // because we don't have a good way to access the state
            // for an item in the massages array state in DateTimeSelectField.
            // We need to be able to know the currently selected date for a given
            // massage in order to show a 'Current reservation' label in the dropdown.
            currentSelectedDate: currentMassage?.date,
            onChangeDate,
            isHotelBooking,
            timeInitialLabel: currentMassage?.productNo
              ? control.context.t(globalBookingMessages.labels.chooseTime)
              : currentMassage?.productNo
              ? control.context.t(
                  massageMessages.info.noAvailabilityDisabledLabel
                )
              : isFloat
              ? control.context.t(massageMessages.info.floatSelectDisabledLabel)
              : control.context.t(
                  massageMessages.info.massageSelectDisabledLabel
                ),
          },
        })}
      />
      {/* Todo: remove this feature flag check when we stop using it*/}
      {control.flow.setupHook?.enableGuestName && currentMassage?.productNo && (
        <FieldRenderer
          control={control}
          screenTheme={screenTheme}
          item={buildInputText({
            id: `massages[${number}].guestName`,
            props: {
              label: 'Name of guest',
              placeholder: '',
              maxLength: isCoupleFloat ? 30 : 60,
            },
            layout: {
              spacing: {
                mt: { xs: 0.5 },
              },
            },
          })}
        />
      )}
      {/* Todo: remove this feature flag check when we stop using it*/}
      {control.flow.setupHook?.enableGuestName &&
        currentMassage?.productNo &&
        isCoupleFloat && (
          <FieldRenderer
            control={control}
            screenTheme={screenTheme}
            item={buildInputText({
              id: `massages[${number}].guestName2`,
              props: {
                label: 'Name of guest 2',
                placeholder: '',
                maxLength: 30,
              },
              layout: {
                spacing: {
                  mt: { xs: 0.5 },
                },
              },
            })}
          />
        )}
    </Card>
  )
}
