import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'

interface IStepperProps {
  stepsLength: number
  currentStepIndex: number
  width?: string
  useLinkNavigation?: boolean
  canSkipForward?: boolean
  onClick?: (stepIndex: number) => void
}

const StepsContainer = styled.div<{ $width: string }>`
  display: flex;
  width: ${({ $width }) => $width};
  max-width: 400px;
  gap: 7px;
  padding-left: 52px;
  padding-right: 52px;
  margin: auto;
  align-self: center;
`

const Step = styled.a<{ done?: boolean }>`
  display: flex;
  flex: 1;
  height: 3px;
  background: ${props => (props.done ? '#52A5B8' : '#c4c4c4')};
`

export const Stepper = ({
  stepsLength,
  currentStepIndex,
  width = '60%',
  useLinkNavigation = true,
  canSkipForward = true,
  onClick,
}: IStepperProps) => (
  <StepsContainer $width={width}>
    {[...Array(stepsLength)].map((_value, index) => {
      if (useLinkNavigation) {
        return (
          <Link href={`?step=${index + 1}`} key={`?step=${index + 1}`}>
            <Step
              aria-label={`step number ${index + 1}`}
              done={index < currentStepIndex}
            />
          </Link>
        )
      }
      return (
        <Step
          key={index + 1}
          aria-label={`step number ${index + 1}`}
          done={index < currentStepIndex}
          onClick={() =>
            canSkipForward
              ? onClick?.(index)
              : currentStepIndex > index && onClick?.(index)
          }
        />
      )
    })}
  </StepsContainer>
)
