import React from 'react'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { DoneIcon } from 'bl-common/src/elements/Icons/DoneIcon'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
type ListItemProps = {
  locationName: string
  locationAddress: string
  selected: boolean
  onClick: (location) => void
  themeStyle?: PartialBookingEngine['transportationFlow']['locationPicker']['locationSearch']['locationList']['listItem']
}

const Done = styled.div({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: theme.spacing[1.5],
  height: 16,
  width: 16,
})

const Card = styled.button<{
  selected?: boolean
  themeStyle?: ListItemProps['themeStyle']
}>(({ selected, themeStyle }) => ({
  colors: selected && colors.white,

  cursor: 'pointer',
  display: 'block',
  padding: theme.spacing[1],
  marginLeft: theme.spacing[-1],
  position: 'relative',
  textAlign: 'left',
  transition: 'background 300ms, transform 200ms',
  width: '100%',
  background: selected
    ? themeStyle?.selectedBackground ?? colors.deepBlue
    : undefined,
  borderRadius: themeStyle?.borderRadius ?? 0,
}))

export const ListItem = ({
  locationName,
  locationAddress,
  selected,
  onClick,
  themeStyle,
}: ListItemProps) => {
  return (
    <Card
      selected={selected}
      onClick={onClick}
      aria-label={locationName}
      type="button"
      themeStyle={themeStyle}
    >
      <Type
        preset="textLarge"
        right={{ xs: 0.5 }}
        maxWidth={300}
        style={{
          color: selected
            ? themeStyle?.selectedTextColor ?? colors.white
            : themeStyle?.textColor ?? colors.dark,
        }}
        inline
      >
        {locationName}
      </Type>
      {!!locationAddress && (
        <Type
          preset="labelSmall"
          maxWidth={300}
          style={{
            color: selected
              ? themeStyle?.selectedTextColor ?? colors.white
              : themeStyle?.textColor ?? colors.dark,
            opacity: 0.5,
          }}
        >
          {locationAddress}
        </Type>
      )}

      {selected && (
        <Done>
          <DoneIcon color={themeStyle?.checkmarkColor ?? colors.white} />
        </Done>
      )}
    </Card>
  )
}

export default ListItem
