import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { colors } from '../../constants/colors'
import { theme } from '../../styles/theme'
import { PartialBookingEngine } from '../../styles/types'
import { media, mediaObj } from '../../utils/media'

export const Timeline = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Content = styled.div<{
  themeStyle?: PartialBookingEngine['itineraryField']
}>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spacing[1.5]};
  padding-left: ${({ theme }) => theme.spacing[1]};
  position: relative;

  ::before {
    content: '';
    border: 1px dashed #e0e0e0;
    bottom: -7px;
    left: -1.4px;
    position: absolute;
    top: 10px;
  }

  ::after {
    content: '';
    background: ${props => props?.themeStyle?.dotColor ?? colors.fountainBlue};
    border-radius: 50%;
    position: absolute;
    height: 8px;
    width: 8px;
    top: 7px;
    left: -4px;
    ${media.md(css`
      top: 5px;
    `)}

    ${media.mlg(css`
      top: 7px;
    `)}
  }
`

export const TimelineItem = styled.div`
  display: flex;
  width: 100%;

  :only-child {
    ${Content} {
      ::before {
        display: none;
      }
    }
  }

  :last-child {
    ${Content} {
      ::before {
        display: none;
      }
    }
  }
`

export const Time = styled.div<{ hasMinWidth?: boolean }>(
  ({ hasMinWidth }) => ({
    paddingRight: theme.spacing[1],
    minWidth: hasMinWidth && 95,
    display: 'flex',
    justifyContent: 'flex-end',
    [mediaObj.md]: {
      minWidth: hasMinWidth && theme.spacing[6],
    },
    p: {
      fontVariantNumeric: 'tabular-nums',
    },
  })
)

export const Hidden = styled(motion.div)`
  overflow: hidden;
  height: auto;
  transition: max-height 500ms ease;
  transform-origin: 0 0;

  ${TimelineItem} {
    ${Content} {
      ::before {
        display: block;
      }
    }
  }
`

export const ExtraItemsButton = styled(motion.button)<{
  $themeStyle?: PartialBookingEngine['itineraryField']
}>`
  cursor: pointer;
  color: ${props =>
    props?.$themeStyle?.extraItemButtonColor ?? colors.deepBlue};
  text-align: left;
`
export const Close = styled(motion.div)`
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing[1]};
  position: absolute;
  right: ${({ theme }) => theme.spacing[-1]};
  top: ${({ theme }) => theme.spacing[-1]};
`
