import React, { useEffect, useReducer } from 'react'
import styled, { css } from 'styled-components'

import { getImageUrl } from '../units/SmartImage/getImageUrl'

type BlurryProps = {
  hide?: boolean
}

const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`

const Blurry = styled.div<BlurryProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  filter: blur(8px);
  left: 0;
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(1.05);
  transition: opacity 2000ms;
  z-index: 2;

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
    `}
`

const _Image = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`

const SET_SRC = 'setSrc'
const SET_LOADING = 'setLoading'

const reducer = (state, action) => {
  switch (action.type) {
    case SET_SRC:
      return {
        ...state,
        mainSrc: action.src,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
  }
}

export const ProgressiveImage = ({ image, skipBlur = false }) => {
  const refIsRemoved = React.useRef(false)
  const [state, dispatch] = useReducer(reducer, {
    mainSrc: '',
    thumbSrc: getImageUrl(image, {
      width: 100,
      exact: true,
      format: null,
      quality: null,
    }),
    loading: true,
  })

  useEffect(() => {
    // This is needed for React strict mode, since all useEffects will be called twice.
    refIsRemoved.current = false
    const mainImage = new Image()

    const mainSrc = getImageUrl(image, {
      width: 1280,
    })

    mainImage.onload = () => {
      // Don't dispatch if the component has been unmounted
      if (!refIsRemoved.current) {
        dispatch({ type: SET_SRC, src: mainSrc })
      }
    }

    if (state.mainSrc !== mainSrc) {
      mainImage.src = mainSrc
    }

    return () => {
      refIsRemoved.current = true
    }
  }, [])

  useEffect(() => {
    if (state.mainSrc) {
      dispatch({ type: SET_LOADING, loading: false })
    }
  }, [state.mainSrc])

  return (
    <ImageContainer>
      <_Image
        style={{
          backgroundImage: `url(${state.mainSrc})`,
        }}
      />
      <Blurry
        style={{ backgroundImage: `url(${state.thumbSrc})` }}
        hide={skipBlur || !state.loading}
      />
    </ImageContainer>
  )
}
