import React, { useContext } from 'react'
import styled, { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { theme } from 'bl-common/src/styles/theme'
import { PartialBookingEngine } from 'bl-common/src/styles/types'
import { mediaObj } from 'bl-common/src/utils/media'
import { globalBookingMessages } from 'bl-flows/src/messages/global'
import { FlowComponent, FlowOverviewField } from 'bl-flows-core'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'

import { getFlowValue } from '../../utils'

type OverviewFieldProps = FlowComponent & FlowOverviewField['props']

const OverviewTable = styled.div({
  marginBottom: theme.spacing[2],
})

const Wrapper = styled.div<{ showWarning: boolean }>(({ showWarning }) => ({
  marginTop: theme.spacing[1],
  marginBottom: showWarning ? theme.spacing[1] : theme.spacing[4.5],

  [mediaObj.md]: {
    marginTop: 0,
  },
}))

const Overview = styled.div({
  marginBottom: theme.spacing[1.5],
})

const ItemWrapper = styled.div<{
  themeStyle?: PartialBookingEngine['overviewField']
}>(({ themeStyle }) => ({
  borderBottom: `1px solid ${themeStyle?.borderColor ?? '#E5E5E5'}`,
}))

const Item = styled.div<{
  themeStyle?: PartialBookingEngine['overviewField']
}>(({ themeStyle }) => ({
  borderTop: `1px solid ${themeStyle?.borderColor ?? '#E5E5E5'}`,

  paddingTop: theme.spacing[1],
  paddingBottom: theme.spacing[1],
}))

const ItemInfo = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing[1],
})

const Totals = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  rowGap: theme.spacing[0.5],
  marginBottom: theme.spacing[2],
})

const Prices = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  gap: 8,
  flexShrink: 0,
  alignItems: 'flex-start',
})

export const OverviewField = ({
  screenTheme,
  ...props
}: OverviewFieldProps) => {
  const items = getFlowValue(props.items, props.control)
  const firstHeading = getFlowValue(props.firstTableHeading, props.control)
  const secondHeading = getFlowValue(props.secondTableHeading, props.control)
  const totalPrice = getFlowValue(props.totalPrice, props.control)
  const isEditing = getFlowValue(props.isEditing, props.control)
  const isHotelBooking = props.isHotelBooking
  const noTableHeading = props.noTableHeading
  const showWarning = props.control.screen.setupHook?.showWarning ?? false
  const unchangedRows = getFlowValue(props.unchangedRows, props.control)
  const parentControl = props.control.parentFlowControl

  const useMCPPayment =
    (parentControl?.id === 'spaBookingFlow' ||
      parentControl?.id === 'retreatBookingFlow') &&
    parentControl?.context?.useMCPPayment

  const { currency, exchangeRates } = useContext(CurrencyContext)

  const theme = useTheme()
  const themeStyle = theme.bookingEngine?.[screenTheme]?.overviewField

  const getTableHeading = () => {
    if (firstHeading) {
      return firstHeading
    } else if (isEditing) {
      return props.control.context.t(
        globalBookingMessages.labels.overviewPreviousBooking
      )
    } else if (isHotelBooking) {
      return props.control.context.t(
        globalBookingMessages.labels.chargedBeforeArrivalHotel
      )
    } else {
      return props.control.context.t(
        globalBookingMessages.labels.chargedUponArrival
      )
    }
  }

  const heading = getTableHeading()

  // We don't want to show the price disclaimer if the currency is ISK or if we are using MCP payment
  const showPriceDisclaimer = currency !== 'ISK' && !useMCPPayment

  return (
    <Wrapper showWarning={showWarning}>
      <Overview>
        <OverviewTable>
          {!noTableHeading && (
            <Type preset="textLarge" weight="bold" bottom={1}>
              {heading}
            </Type>
          )}
          <ItemWrapper themeStyle={themeStyle}>
            {items
              ?.filter(
                item =>
                  // remove items that do not have firstText, firstPrice or discountedFirstPrice
                  item.firstText || item.firstPrice || item.discountedFirstPrice
              )
              .map((item, index) => (
                <Item
                  key={`${item.firstText}-${index}`}
                  themeStyle={themeStyle}
                >
                  <ItemInfo>
                    <div>
                      <Type
                        preset="text"
                        color={themeStyle?.textColor ?? colors.midGrey}
                      >
                        {item.firstText}
                      </Type>
                      {!!item.secondLineFirstText && (
                        <Type
                          preset="text"
                          color={themeStyle?.textColor ?? colors.midGrey}
                          style={{ marginTop: 5 }}
                        >
                          {item.secondLineFirstText}
                        </Type>
                      )}
                    </div>
                    <Prices>
                      {!!item.firstPrice && (
                        <Type
                          preset="text"
                          color={themeStyle?.textColor ?? colors.midGrey}
                          style={{
                            textDecoration:
                              !!item.discountedFirstPrice && 'line-through',
                            opacity: !!item.discountedFirstPrice && 0.7,
                          }}
                        >
                          {item.firstPrice}
                        </Type>
                      )}
                      {!!item.discountedFirstPrice && (
                        <Type
                          preset="text"
                          color={themeStyle?.textColor ?? colors.midGrey}
                        >
                          {item.discountedFirstPrice}
                        </Type>
                      )}
                    </Prices>
                  </ItemInfo>
                </Item>
              ))}
          </ItemWrapper>
        </OverviewTable>
        {isEditing && (
          <OverviewTable>
            <Type preset="textLarge" weight="bold" bottom={1}>
              {secondHeading
                ? secondHeading
                : props.control.context.t(
                    globalBookingMessages.labels.overviewNewBooking
                  )}
            </Type>
            <ItemWrapper themeStyle={themeStyle}>
              {items
                ?.filter(
                  item =>
                    // remove items that do not have secondText or secondPrice
                    item.secondText || item.secondPrice
                )
                ?.map((item, index) => (
                  <Item
                    key={`${item.secondText}-${index}`}
                    themeStyle={themeStyle}
                  >
                    <ItemInfo>
                      <div>
                        <Type
                          preset="text"
                          color={colors.midGrey}
                          style={{
                            ...(item.notAvailable && {
                              textDecoration: 'line-through',
                            }),
                          }}
                        >
                          {item.secondText}
                        </Type>
                        {!!item.secondLineSecondText && (
                          <Type
                            preset="text"
                            color={colors.midGrey}
                            style={{ marginTop: 5 }}
                          >
                            {item.secondLineSecondText}
                          </Type>
                        )}
                      </div>
                      <Prices>
                        <Type preset="text" color={colors.midGrey}>
                          {item.secondPrice}
                        </Type>
                      </Prices>
                    </ItemInfo>
                  </Item>
                ))}
            </ItemWrapper>
          </OverviewTable>
        )}
        {!!unchangedRows?.length && (
          <OverviewTable>
            <Type preset="textLarge" weight="bold" bottom={1}>
              {props.control.context.t(
                globalBookingMessages.labels.overviewUnchanged
              )}
            </Type>
            <ItemWrapper themeStyle={themeStyle}>
              {unchangedRows?.map(item => (
                <Item key={item.text} themeStyle={themeStyle}>
                  <ItemInfo>
                    <div>
                      <Type preset="text" color={colors.midGrey}>
                        {item.text}
                      </Type>
                    </div>
                    <Prices>
                      <Type preset="text" color={colors.midGrey}>
                        {item.price}
                      </Type>
                    </Prices>
                  </ItemInfo>
                </Item>
              ))}
            </ItemWrapper>
          </OverviewTable>
        )}
      </Overview>
      {!!totalPrice && (
        <Totals>
          <Type preset="text" weight="bold">
            {props.control.context.t(globalBookingMessages.labels.total, {
              price: formatPrice(
                calcPrice(
                  totalPrice,
                  // exchange rate for ISK is 1, so fallback to that for MYB
                  exchangeRates?.[currency] ?? 1
                ),
                currency.length ? currency : 'ISK',
                false
              ),
            })}
          </Type>
          {showPriceDisclaimer && (
            <Type preset="textSmall">
              {props.control.context.t(globalBookingMessages.labels.chargedAs, {
                price: formatPrice(totalPrice, 'ISK', false),
              })}
            </Type>
          )}
        </Totals>
      )}
    </Wrapper>
  )
}
