import React, { ReactElement, ReactNode } from 'react'
import { Options } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import { Asset } from 'contentful'

import { PriceValue } from 'bl-common/src/elements/Amount'
import { ButtonPreset } from 'bl-common/src/elements/Button/Button'
import {
  CSSValue,
  Presets,
  Weight,
} from 'bl-common/src/elements/Typography/Typography'
import {
  BookingEngine,
  DeepPartial,
  ScreenTheme,
} from 'bl-common/src/styles/types'
import { ProductsOffer } from 'bl-graphql'
import {
  HighlandBaseTransferProductIds,
  PRODUCT_IDS,
  ProductId,
} from 'bl-utils/src/ProductIds'

import {
  FlowCallback,
  FlowComponent,
  FlowControl,
  FlowField,
  FlowValue,
  FlowValueCallback,
} from './flow'
import {
  FlowButtonRoot,
  FlowFieldRoot,
  FlowInputRoot,
  FlowRoot,
  FlowTextFieldRoot,
} from './roots'
import { AtLeastOne } from './typeUtils'

export interface FlowBreadcrumbField extends FlowFieldRoot {
  type: 'field'
  role: 'button'
  subType: 'breadcrumb'
  props: Record<string, any>
}

export interface FlowInputTextField extends FlowInputRoot<string> {
  subType: 'inputText'
  props: {
    label: FlowValue
    placeholder: FlowValue
    type?: string
    disabled?: boolean
    format?: string
    maxLength?: number
    required?: boolean
    emptyLabel?: boolean
    initialValue?: FlowValue
    onBlur?: FlowValueCallback<string>
  }
}

export interface FlowPhoneInputField extends FlowInputRoot<string> {
  subType: 'inputPhone'
  props: {
    label: FlowValue
    required?: boolean
    countryCode?: FlowValue
    inputGap?: string
  }
}

export interface FlowTextAreaField extends FlowInputRoot<string> {
  subType: 'textarea'
  props: {
    label: FlowValue
    placeholder: FlowValue
    rows?: FlowValue<number>
    maxLength?: FlowValue<number>
    defaultValue?: FlowValue<string>
  }
}

export interface FlowPickerField extends FlowInputRoot<number> {
  subType: 'picker'
  props: {
    label: FlowValue
    value?: FlowValue<number>
    min?: FlowValue<number>
    max?: FlowValue<number>
    onChange?: FlowValueCallback<number>
    onDisabledIncrementClick?: FlowValueCallback<number>
  }
}

export interface FlowCheckboxField extends FlowInputRoot<boolean> {
  subType: 'checkbox'
  props: {
    label: FlowValue
    value?: FlowValue<boolean>
    onChange?: FlowValueCallback<boolean>
    required?: boolean
    labelPreset?: Presets
  }
}

export interface FlowCalendarField extends FlowInputRoot<Date> {
  subType: 'calendar'
  props: {
    onClick: FlowValueCallback<string>
    selected: FlowValue<Date>
    excludedDates?: FlowValue<Date[]>
    startDate?: FlowValue<Date>
    endDate?: FlowValue<Date>
    isLoading?: FlowValue<boolean>
    spinnerColor?: FlowValue<string>
    ariaLabelForFullyBooked?: FlowValue<string>
    themeStyle?: DeepPartial<BookingEngine['calendarField']>
  }
}

export interface FlowDatePickerField extends FlowInputRoot<Date[]> {
  subType: 'datepicker'
  props: {
    onChange: FlowValueCallback<Date[]>
    startDate?: FlowValue<Date>
    endDate?: FlowValue<Date>
    minDate?: FlowValue<Date>
    maxDate?: FlowValue<Date>
    monthsShown?: number
    layout: 'vertical' | 'horizontal'
  }
}

export interface FlowSpaPackageTableField<T> extends FlowInputRoot<T> {
  subType: 'spaPackage'
  props: {
    arrivalDate: FlowValue<Date>
    value?: FlowValue<T>
    onClick: FlowValueCallback<T>
    onMoveArrivalDate: FlowValueCallback<Date>
  }
}

export interface FlowTextField extends FlowTextFieldRoot {
  subType: 'text'
  props: {
    value: FlowValue
    type:
      | 'heading'
      | 'headingMedium'
      | 'headingSmall'
      | 'subheading'
      | 'paragraph'
      | 'disclaimer'
      | 'label'
      | 'html'
      | 'textLarge'
      | 'text'
    as?: React.ElementType
    color?: string
    preset?: Presets
    textAlign?: 'left' | 'center' | 'right'
    maxWidth?: CSSValue<'maxWidth'>
    mask?: boolean
  }
}

export interface FlowRichTextField extends FlowTextFieldRoot {
  subType: 'richtext'
  props: {
    value: FlowValue<Document>
    customOptions?: Options
    style?: React.CSSProperties
    themeStyle?: {
      listMarkerColor?: string
    }
  }
}

type FlowButtonFieldProps = {
  label: FlowValue
  preset?: string
  paddingSize?: 'small' | 'menu' | 'medium' | 'large'
  isDisabled?: FlowCallback<boolean> | boolean
} & (
  | {
      isSubmit: true
      onClick?: FlowCallback
    }
  | {
      isSubmit?: false
      onClick: FlowCallback
    }
)

type FlowProgressButtonFieldProps = {
  label: FlowValue
  loading?: FlowValue<boolean>
  preset?: ButtonPreset
  isDisabled?: FlowValue<boolean>
  onClick?: ((control: FlowControl) => Promise<boolean>) | (() => void)
  onComplete?: FlowCallback
}

export interface FlowButtonField extends FlowButtonRoot {
  subType: 'button'
  screenTheme?: ScreenTheme
  props: FlowButtonFieldProps
}

export interface FlowProgressButtonField extends FlowButtonRoot {
  subType: 'progressButton'
  screenTheme?: ScreenTheme
  props: FlowProgressButtonFieldProps
}

export interface FlowCardButtonField extends FlowFieldRoot {
  subType: 'cardButton'
  role: 'button'
  props: {
    title: FlowValue
    description: FlowValue<string | ReactNode>
    ctaLabel: FlowValue
    secondaryCtaLabel?: ReactNode
    inCartLabel?: FlowValue
    image?: FlowValue<Asset>
    onClick?: FlowCallback
    onAsyncClick?: FlowCallback
    onComplete?: FlowCallback
    secondaryOnClick?: FlowCallback
    smallPrint?: FlowValue
    disclaimer?: ReactNode
    linePrice?: FlowValue<number>
    price?: FlowValue<number> | FlowValue
    priceFormat?: FlowValue
    isClosed: FlowValue<boolean>
    isDisabled?: FlowValue<boolean>
    inCart?: FlowValue<boolean>
    pricePosition?: 'top' | 'bottom'
    linkProps?: { href: string; isExternalLink?: boolean }
    focusCardLabel?: FlowValue<string>
    themeStyle?: {
      svgColor?: string
      card?: {
        borderRadius?: number
        overflow?: string
        background?: string
        color?: string
        focusBackground?: string
        focusLabelBackground?: string
        focusLabelBorder?: string
        focusLabelBorderRadius?: number
      }
      image?: {
        borderTopLeftRadius?: number
        borderBottomLeftRadius?: number
        overflow?: string
      }
      button?: {
        color?: string
        fontWeight?: Weight | number
      }
      line?: {
        background?: string
      }
      price?: {
        fontWeight?: string
      }
    }
  }
}

export interface FlowProductCardField extends FlowFieldRoot {
  subType: 'productCard'
  role: 'button'
  props: {
    title: FlowValue
    description: FlowValue
    ariaLabel: FlowValue
    price?: FlowValue<number>
    priceFormat?: FlowValue
    ctaLabel: FlowValue
    onClick: FlowCallback
    isDisabled?: FlowCallback<boolean> | boolean
  }
}

type ChildFields = FlowValue<AtLeastOne<FlowField> | FlowRoot>

export interface FlowModalField extends FlowFieldRoot {
  subType: 'modal'
  role: 'modal'
  props: {
    isOpen: FlowValue<boolean>
    onHide: FlowValueCallback<unknown>
  }
  children: ChildFields
}

export interface FlowDrawerField extends FlowFieldRoot {
  subType: 'drawer'
  role: 'modal'
  props: {
    isOpen: FlowValue<boolean>
    onHide: FlowValueCallback<unknown>
  }
  children: ChildFields
}

type FlowCustomFieldProps =
  | {
      component: React.FC<FlowComponent>
      render?: never
    }
  | {
      component?: never
      render: (control: FlowControl) => ReactElement
    }

export interface FlowCustomField extends FlowFieldRoot {
  subType: 'custom'
  role: 'custom'
  defaultValue?: Record<string, FlowValue<any>>
  props: FlowCustomFieldProps
}

export interface FlowMultipleFields extends FlowFieldRoot {
  subType: 'multiple'
  role: 'custom'
  defaultValue?: Record<string, FlowValue<any>>
  props: {
    fields: FlowField[]
  }
}

export interface FlowScreenErrorField extends FlowFieldRoot {
  subType: 'screenError'
  role: 'error'
  props: {
    // Only render errors for certain fields
    fieldIdsToRender?: string[]
  }
}
export interface FlowDiscountAccordionField extends FlowInputRoot<any> {
  subType: 'discountAccordion'
  props: {
    label: FlowValue
    placeholder: FlowValue
    activePromoCode: FlowValue<string>
    activeGiftCards: FlowValue<
      { no: string; balance: number; chargeAmount: number }[]
    >
    discountedAmount: FlowValue<number>
    giftCardAmount: FlowValue<number>
    addPromoCode: (promoCode: string, control: FlowControl) => Promise<any>
    addGiftCard: (giftCard: string, control: FlowControl) => Promise<any>
    removeGiftCard: (giftCard: string, control: FlowControl) => Promise<void>
  }
}

export interface FlowSelectField extends FlowInputRoot<string> {
  subType: 'select'
  props: {
    options: FlowValue<
      AtLeastOne<{ label: string; value: string; disabled?: boolean }>
    >
    onChange?: FlowValueCallback<string, void>
    label?: FlowValue
    placeholder?: FlowValue
    isDisabled?: FlowValue<boolean>
    required?: boolean
    initialLabel?: FlowValue
    initialValue?: FlowValue
  }
}

export interface FlowImageField extends FlowFieldRoot {
  subType: 'image'
  role: 'image'
  props: {
    height: string
    imageSrc: FlowValue
    alt: FlowValue
  }
}

export interface FlowDateTimeSelectField
  extends FlowInputRoot<{
    date: Date
    time: string
  }> {
  subType: 'dateTimeSelect'
  role: 'input'
  props: {
    dates: Date[]
    timeSlots: TimeSlot[]
    type?: string
    isCard?: boolean
    multiple?: boolean
    guests?: number
    bookableQuantity?: number
    showCurrentReservationLabel?: boolean
    currentReservationDate?: string
    currentSelectedDate?: string
    skipOwnError?: boolean
    onChangeDate?: (newDate: string, control: FlowControl) => void
    isHotelBooking?: boolean
    dateInitialLabel?: string
    timeInitialLabel?: string
  }
}

export interface FlowAvailableDateTimesField extends FlowFieldRoot {
  subType: 'availableDateTimes'
  role: 'custom'
  defaultValue: { date: Date; time: string }
  props: {
    type: FlowValue<
      | 'lava'
      | 'transportationPickup'
      | 'transportationDeparture'
      | 'spaRestaurant'
    > // TODO: Is this the best way for typing this?
    bookingInfo: FlowValue<SubFlowBookingInfo>
    activities?: FlowValue<any>
    transportation?: FlowValue<SubFlowTransportation>
    hideRecommendedSlots?: FlowValue<boolean>
    showCurrentReservationLabel?: FlowValue<boolean>
    currentReservationDate?: FlowValue<string>
    guests?: FlowValue<number>
    isEditing?: FlowValue<boolean>
    isHotelBooking?: FlowValue<boolean> //either in HBE or MYB
  }
}

export interface FlowOverviewField extends FlowFieldRoot {
  subType: 'overview'
  role: 'custom'
  defaultValue: null
  props: {
    items: FlowValue<OverviewItems[]>
    isEditing: FlowValue<boolean>
    isHotelBooking?: boolean
    noTableHeading?: boolean
    totalPrice?: FlowValue<number>
    firstTableHeading?: FlowValue<string>
    secondTableHeading?: FlowValue<string>
    unchangedRows?: FlowValue<{ text: string; price?: string }[]>
  }
}

export interface FlowAccordionField extends FlowFieldRoot {
  subType: 'accordion'
  role: 'custom'
  defaultValue: null
  props: {
    label: FlowValue<string>
    children: FlowValue<string | ReactNode>
    onClick?: FlowCallback
    labelColor?: FlowValue<string>
    withBorder?: FlowValue<boolean>
    startExpanded?: FlowValue<boolean>
  }
}

export interface FlowConfirmationSummaryField extends FlowFieldRoot {
  subType: 'confirmationSummary'
  role: 'custom'
  defaultValue: null
  props:
    | ConfirmationSummaryHotel
    | ConfirmationSummarySpa
    | ConfirmationSummaryActivities
    | ConfirmationSummaryProducts
    | ConfirmationSummaryPrivateTransfer
}

export interface ConfirmationSummaryHotel {
  type: 'hotel'
  rooms: FlowValue<ConfirmationSummaryRoom[]>
}

export interface ConfirmationSummarySpa {
  type: 'spa'
  spa: FlowValue<ConfirmationSummarySpaItem>
}

export interface ConfirmationSummaryActivities {
  type: 'activities'
  activities: FlowValue<ConfirmationSummaryActivity[]>
}

export interface ConfirmationSummaryProducts {
  type: 'products'
  products: FlowValue<ConfirmationSummaryProduct[]>
}

export interface ConfirmationSummaryPrivateTransfer {
  type: 'privateTransfer'
  privateTransfer: FlowValue<ConfirmationSummaryPrivateTransferItem>
}

export interface ConfirmationSummaryActivity {
  numberOfPersons?: number
  productId: ProductId
  reservationDate: string
  title?: string
  notAvailable?: boolean
  time?: string
  pending?: boolean
}

export interface ConfirmationSummaryHighlandBaseTransfer {
  numberOfPersons?: number
  productId: HighlandBaseTransferProductIds
  reservationDate: string
  title?: string
  time?: string
  qty: number
}

export interface ConfirmationSummaryProduct {
  productId: string
  title: string
  qty?: number
}

export interface ConfirmationSummaryPrivateTransferItem {
  arrival?: {
    productId: ProductId
    time: string
  }
  departure?: {
    productId: ProductId
    time: string
  }
}

export interface ConfirmationSummaryBusTransfer {
  dropoffDate: string
  dropoffTime: string
  dropoffLocationName: string
  numberOfPersons?: number
  pickupLocationName: string
  pickupDate: string
  pickupTime: string
}

export interface ConfirmationSummarySpaItem {
  packageTitle: string
  date: string
  time: string
  adults: number
  youngAdults?: number
  children: number
  activities?: ConfirmationSummaryActivity[]
  busTransfer?: ConfirmationSummaryBusTransfer
  products?: ConfirmationSummaryProduct[]
}

export interface ConfirmationSummaryRoom {
  adults: number
  children: number
  infants?: number
  roomProductId: string
  roomTitle: string
  arrivalDate: string
  departureDate: string
  packageTitle: string
  packageProductId: string
  products?: ConfirmationSummaryProduct[]
  activities?: ConfirmationSummaryActivity[]
  privateTransfer?: ConfirmationSummaryActivity[] // Keeping private transfer seperate because of the setup on the screen
  highlandBaseTransferItems?: ConfirmationSummaryHighlandBaseTransfer[]
}

export interface FlowAddProductCardField extends FlowFieldRoot {
  subType: 'addProductCard'
  role: 'custom'
  defaultValue: { [key: string]: number }
  props: {
    title: FlowValue<string>
    description: FlowValue<string>
    price: FlowValue<number>
    discountedPrice?: FlowValue<number>
    image?: Asset
    hasImage?: boolean
  }
}

export interface FlowSelectDayVisitTimeField extends FlowFieldRoot {
  subType: 'selectDayVisitTime'
  role: 'custom'
  defaultValue: { selectedTime: string }
  props: {
    currentAdmission?: SubFlowActivity
    packageProductNo: FlowValue<string>
    guestCount: FlowValue<number>
    isLoadingAvailability: FlowValue<boolean>
    availabilityData: FlowValue<any>
    hasAvailabilityError: FlowValue<boolean>
    refetchAvailability: FlowValue<() => void>
    getAdmissionTitle: FlowValue<
      (
        control: FlowControl,
        selectedPackage: string,
        isShortTitle?: boolean
      ) => string
    >
    hidePrice?: boolean
    date?: FlowValue<string>
    isMYB?: FlowValue<boolean>
    showCurrencySelector?: boolean
    showDatePicker?: boolean
    premiumProductId?: typeof PRODUCT_IDS.SpaPremium
    isMembershipInCart?: FlowValue<boolean>
    membershipProductId?: FlowValue<string>
    onPrevDayClick?: (control: FlowControl) => void
    onNextDayClick?: (control: FlowControl) => void
    onClick?: FlowValueCallback<{
      selectedTime: string
      selectedPrice: number
      premiumAvailability?: ProductsOffer
      productId?: string
      offerId?: string
      childOfferId?: string
    }>
    messages?: FlowValue<{
      [key: string]: {
        id: string
        defaultMessage: string
        description: string
      }
    }>
  }
}

export interface FlowChangeDateField extends FlowFieldRoot {
  subType: 'changeDate'
  role: 'custom'
  defaultValue: { selectedTime: string }
  props: {
    isLoadingAvailability: FlowValue<boolean>
    date?: FlowValue<string>
    onPrevDayClick?: (control: FlowControl) => void
    onNextDayClick?: (control: FlowControl) => void
    messages?: FlowValue<{
      [key: string]: {
        id: string
        defaultMessage: string
        description: string
      }
    }>
  }
}

export interface FlowItineraryField extends FlowFieldRoot {
  subType: 'itinerary'
  role: 'itinerary'
  props: {
    items: FlowValue<
      {
        time: string
        date: string
        title: string
        subtitle: string
        faded?: boolean
      }[]
    >
    isHotelBooking?: boolean
    moreLabel?: {
      id: string
      defaultMessage: string
      description: string
    }
  }
}

export interface FlowDisclaimerField extends FlowFieldRoot {
  subType: 'disclaimer'
  role: 'disclaimer'
  props: {
    value: FlowValue<string | React.ReactNode>
    color?: string
    weight?: string
    textColor?: string
    preset?: Presets
    showDisclaimer?: FlowValue<boolean>
    type?: 'border' | 'circle'
    scrollIntoView?: FlowValue<boolean>
  }
}

// Extra types

export interface TimeSlot {
  available?: number
  time?: string
  date?: string
}

export interface SubFlowBookingInfo {
  entryDate?: Date
  hotelDates?: { arrivalDate: Date; departureDate: Date }
  adults?: number
  children?: number
  youngAdults?: number
  infants?: number
  property?: string
  bookingId?: string
}

export interface SubFlowActivity {
  title?: string
  productNo?: string
  quantity?: number
  guests?: number
  date?: string
  duration?: number
  lineNo?: string
  comment?: string
  numberOfChildren?: number
  numberOfPersons?: number
  numberOfYoungAdults?: number
}

export interface SubFlowTransportation {
  price: number
  departureDate?: string
  departureTime?: string
  dropoffLocationID?: string
  dropoffLocationName?: string
  pickupLocationID?: string
  pickupLocationName?: string
  pickupTime?: string
}

export interface SubFlowUpgradeAdmissionCard {
  title: string
  subtitle?: string
  name: string
  buttonText?: string
  isRetreat?: boolean
  link?: string
  listItems?: {
    fields: { item: string; itemStyle: string }
    metadata: unknown
    sys: unknown
  }[]
  priceFormat?: string
  priceRef?: {
    fields: PriceValue
    metadata: unknown
    sys: unknown
  }
  productReference?: unknown
  packageType: string
  image?: Asset
  text?: string
  textColor?: string
  backgroundImage?: unknown
  gradient?: unknown
}

interface OverviewItems {
  firstText: string
  secondLineFirstText?: string
  firstPrice?: string
  discountedFirstPrice?: string
  secondText?: string
  secondLineSecondText?: string
  secondPrice?: string
  notAvailable?: boolean
}
