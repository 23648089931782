import React from 'react'

export const Reload = ({ color = 'black', ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12.16" {...rest}>
    <path
      fill={color}
      d="M10.13,7.27l0,.06A4.19,4.19,0,0,1,7,10.19,4.27,4.27,0,0,1,2.05,7.44c0-.12-.08-.25-.11-.37V7a.43.43,0,0,0,0-.11s0-.07,0-.1v0A4.25,4.25,0,0,1,5.53,1.9,4.26,4.26,0,0,1,9,3L7.76,4.18,12,4.64,11.54.4,10.25,1.69l0,0A6.05,6.05,0,0,0,.09,7.07s0,0,0,.07l0,.14,0,.14a6.1,6.1,0,0,0,5.93,4.74,6.28,6.28,0,0,0,.75,0,6.09,6.09,0,0,0,4.84-3.73.57.57,0,0,0,0-.18A5.81,5.81,0,0,0,12,7.43a.86.86,0,0,0,0-.16Z"
    />
  </svg>
)
